import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { Marker, GoogleMap, useLoadScript } from "@react-google-maps/api";
import { getLocTemplateCoordinates } from "../../services/WhatsappAPI/index";

import pdfIcon from "../../images/icons/pdf-icon.svg";
import Reciver from "../../images/icons/reciver.svg";
import Linkforward from "../../images/icons/link-forward.svg";

const GOOGLE_API_KEY = "AIzaSyASVOh0zf-dgKxPOr4e796luwQzCT150NY";
const libraries = ["places"];

const LocationComponent = ({ templateId, location }) => {
  const [isMapFethcing, setIsMapFetching] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const containerStyleForPreview = {
    width: "100%",
    height: "150px",
  };
  console.log("templateId", location);
  const options = {
    mapTypeControl: false, // Disable Map and Satellite options
    streetViewControl: false, // Disable Pegman (Street View)
    zoomControl: false, // Disable Zoom in/out controls
    fullscreenControl: false, // Disable Fullscreen control
  };

  const { isLoaded: isGoogleLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: libraries,
  });

  const getTemplateCoordinates = async () => {
    setIsMapFetching(true);
    const companyId = localStorage.getItem("companyId");
    const coordinates = await getLocTemplateCoordinates(companyId, templateId);
    setCoordinates({
      lat: coordinates?.data?.components[0]?.parameters[0]?.location?.latitude,
      lng: coordinates?.data?.components[0]?.parameters[0]?.location?.longitude,
    });
    setIsMapFetching(false);
  };

  useEffect(() => {
    if (!location) {
      getTemplateCoordinates();
    } else {
      setCoordinates({
        lat: location?.latitude,
        lng: location?.longitude,
      });
    }
  }, []);

  if (!isMapFethcing && isGoogleLoaded) {
    return (
      <GoogleMap
        center={{
          lat: Number(coordinates?.lat),
          lng: Number(coordinates?.lng),
        }}
        zoom={15}
        mapContainerStyle={containerStyleForPreview}
        options={options}
      >
        <Marker
          position={{
            lat: Number(coordinates?.lat),
            lng: Number(coordinates?.lng),
          }}
        />
      </GoogleMap>
    );
  } else {
    return <p>Map is loading ...</p>;
  }
};

export const UrlPhoneNumber = ({
  components,
  isInbox,
  id,
  isSending,
  template,
}) => {
  const getHeaderData = (headerComp) => {
    console.log(
      "headerComp?.format",
      headerComp?.format == "LOCATION" && template
    );
    if (headerComp?.format == "TEXT") {
      return headerComp?.text?.length > 100 && isSending
        ? headerComp?.text?.substring(0, 100) + "..."
        : headerComp?.text;
    } else if (headerComp?.format == "IMAGE") {
      return (
        <img
          src={headerComp?.example?.header_handle[0]}
          style={{ width: "100%", objectFit: "contain" }}
        />
      );
    } else if (headerComp?.format == "VIDEO") {
      return (
        <ReactPlayer
          controls
          url={headerComp?.example?.header_handle[0]}
          width="100%"
          height={"150px"}
        />
      );
    } else if (headerComp?.format == "DOCUMENT") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            gap: "10px",
          }}
          onClick={() =>
            window
              .open(headerComp?.example?.header_handle[0], "_blank")
              ?.focus()
          }
        >
          <img src={pdfIcon} style={{ width: "32px", height: "38px" }} />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                fontWeight: 500,
                fontSize: "14px",
                fontFamily: "Segoe UI",
                color: "#000000",
                cursor: "pointer",
              }}
            >
              Preview File
            </p>
          </div>
        </div>
      );
    } else if (headerComp?.format == "LOCATION") {
      return (
        <LocationComponent templateId={id} location={template?.location} />
      );
    }
  };

  return (
    <>
      {components?.map((component, index) => {
        if (component.type === "HEADER") {
          return (
            <div
              className="heading"
              style={{
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "21px",
                marginBottom: "15px",
              }}
              key={index}
            >
              {getHeaderData(component)}
              {/* {component.text} */}
            </div>
          );
        } else if (component.type === "BODY") {
          return (
            <div
              className="content"
              key={index}
              style={{ wordWrap: "break-word" }}
            >
              {component.text?.split("\n").map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
            </div>
          );
        } else if (component.type === "FOOTER") {
          return (
            <div
              className="content"
              style={{ opacity: "60%", fontSize: "12px" }}
              key={index}
            >
              {component.text?.split("\n").map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
            </div>
          );
        } else if (component.type === "BUTTONS") {
          if (component.buttons[0].type === "URL" && !isInbox) {
            return (
              <div className="link-footer1 px-0">
                <a href="#">
                  <img src={Linkforward} alt="icon" />{" "}
                  {component.buttons[0].text}
                </a>
                {component.buttons.length > 1 && (
                  <a href="#">
                    {" "}
                    <img src={Reciver} alt="icon" /> {component.buttons[1].text}
                  </a>
                )}
              </div>
            );
          } else if (component.buttons[0].type === "PHONE_NUMBER" && !isInbox) {
            return (
              <div className="link-footer1 px-0">
                <a href="#">
                  {" "}
                  <img src={Reciver} alt="icon" /> {component.buttons[0].text}
                </a>
                {component.buttons.length > 1 && (
                  <a href="#">
                    <img src={Linkforward} alt="icon" />{" "}
                    {component.buttons[1].text}
                  </a>
                )}
              </div>
            );
          } else if (component.buttons[0].type === "PHONE_NUMBER" && isInbox) {
            return (
              <div className="link-footer1 px-0">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <img src={Reciver} alt="icon" style={{ width: "15px" }} />
                  <p
                    style={{
                      paddingLeft: "5px",
                      color: "#0FA6E0",
                      fontWeight: "600",
                    }}
                  >
                    {component.buttons[0].text}
                  </p>
                </div>
                {component.buttons.length > 1 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={Linkforward}
                      alt="icon"
                      style={{ width: "15px" }}
                    />
                    <p
                      style={{
                        paddingLeft: "5px",
                        color: "#0FA6E0",
                        fontWeight: "600",
                      }}
                    >
                      {component.buttons[1].text}
                    </p>
                  </div>
                ) : null}
              </div>
            );
          } else if (component.buttons[0].type === "URL" && isInbox) {
            return (
              <div className="link-footer1 px-0">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={Linkforward} alt="icon" style={{ width: "15px" }} />
                  <p
                    style={{
                      paddingLeft: "5px",
                      color: "#0FA6E0",
                      fontWeight: "600",
                    }}
                  >
                    {component.buttons[0].text}
                  </p>
                </div>
                {component.buttons.length > 1 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={Reciver} alt="icon" style={{ width: "15px" }} />
                    <p
                      style={{
                        paddingLeft: "5px",
                        color: "#0FA6E0",
                        fontWeight: "600",
                      }}
                    >
                      {component.buttons[1].text}
                    </p>
                  </div>
                ) : null}
              </div>
            );
          }
        }
        return null;
      })}
    </>
  );
};
