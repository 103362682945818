import { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";

import { Spinner } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";

import { generateColorHash } from "../../utils/utils";

const UserTags = (props) => {
  const [tags, setTags] = useState([]);
  const [selTags, setSelTags] = useState({ label: "", value: "" });

  console.log(
    "selected tag got : ",
    props?.storedConversations.find(
      (item) => item?._id == props?.selChatData?._id
    )?.tag
  );
  // console.log("selected tag got : ", props?.allTags);

  const setTagsData = () => {
    let temp = [];
    if (props?.allTags && props?.allTags?.length > 0) {
      for (let i = 0; i != props?.allTags?.length; i++) {
        temp.push({
          value: props?.allTags[i]?._id,
          label: props?.allTags[i]?.title,
        });
      }
    }
    setTags(temp);

    let isFound = temp.find((item) => item?.value == props?.selectedTags);
    if (isFound) {
      setSelTags(isFound);
    }
  };

  const getSelTagData = () => {
    let isFound =
      props?.allTags &&
      props?.allTags?.length > 0 &&
      props?.allTags.find(
        (item) =>
          item?._id ==
          props?.storedConversations.find(
            (item) => item?._id == props?.selChatData?._id
          )?.tag
      );
    console.log("isFound", isFound);
    if (isFound) {
      return { label: isFound?.title, value: isFound._id };
    }
    // return null;
    return null;
  };

  useEffect(() => {
    setTagsData();
  }, [props?.allTags]);

  //   const colorStyles = {
  //     multiValue: (styles, { data }) => ({
  //       ...styles,
  //       fontWeight: 600,
  //       color: "#000000",
  //       fontSize: "14px",
  //       backgroundColor: generateColorHash(data?.value?.substring(0, 10)),
  //     }),
  //   };
  console.log("getSelTagData", props?.allTags);
  // const colorStyles = {
  //   singleValue: (styles, { data }) => ({
  //     ...styles,
  //     backgroundColor: generateColorHash(props?.selectedTags?.substring(0, 10)),

  //     display: "inline-block", // Make the background only take the width of the text
  //     padding: "2px 8px", // Adjust padding as needed
  //     borderRadius: "4px",
  //     marginTop: "5px", // Set the selected value color to red
  //   }),
  // };
  const colorStyles = {
    valueContainer: (styles) => ({
      ...styles,
      display: "flex", // Change from grid to flex
      flexWrap: "nowrap", // Prevent wrapping
      padding: "2px 0px",
    }),
    singleValue: (styles, { data }) => {
      return {
        ...styles,
        fontWeight: 600,
        color: "#000000",
        fontSize: "14px",
        backgroundColor: generateColorHash(data?.value?.substring(0, 10)),
        padding: "2px 4px",
        borderRadius: "4px",
        display: "inline-block", // Ensure it only takes up the width of the content
        maxWidth: "unset", // Override the max-width property
        overflow: "visible", // Ensure no content is hidden
        textOverflow: "clip", // Disable text ellipsis
        whiteSpace: "normal", // Allow wrapping if needed
        gridArea: "unset", // Reset the grid-area to avoid grid layout behavior
      };
    },
    placeholder: (styles) => ({
      ...styles,
      fontWeight: "600",
      color: "black",
    }),
  };
  // const colourStyles = {
  //     option: (styles, { data }) => {
  //         return {
  //             ...styles,
  //             backgroundColor: generateColorHash(data?.value?.slice(0, 10)),
  //             color: "#000000",
  //             fontWeight: 400,
  //             cursor: "pointer",
  //             width: "max-content"
  //         };
  //     },
  // };

  if (props?.isTagFetching) {
    return <p>Loading ...</p>;
  }

  return (
    <>
      <div className="user-info-data">
        <div className="user-personal-info">
          <div className="info-row">
            <div className="title mb-2">
              Tag Assigned to this conversation:
              {props?.isNewTagAssigning ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ color: "rgba(22, 94, 91, 1)", marginLeft: "15px" }}
                />
              ) : null}
            </div>
            {props?.isNewTagAssigning ||
            props?.canUserPerformAction == false ? (
              <Tooltip title="You cannot perform this action" arrow>
                <div>
                  <Select
                    styles={colorStyles}
                    isDisabled={true}
                    className="c-select basic-multi-select"
                    options={tags}
                    placeholder={"Choose Tag"}
                    classNamePrefix={`select`}
                    onChange={(value) => {
                      props?.handleTagChange(value?.value);
                      setSelTags({ label: value?.label, value: value?.value });
                    }}
                    isSearchable={false}
                    value={getSelTagData()}
                  />
                </div>
              </Tooltip>
            ) : (
              <Select
                styles={colorStyles}
                isDisabled={false}
                className="c-select basic-multi-select"
                options={tags}
                placeholder={"Choose Tag"}
                classNamePrefix={`select`}
                onChange={(value) => {
                  props?.handleTagChange(value?.value);
                  setSelTags({ label: value?.label, value: value?.value });
                }}
                isSearchable={false}
                value={getSelTagData()}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserTags;
