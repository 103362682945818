import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import ReactPlayer from "react-player";
import {
  StandaloneSearchBox,
  LoadScript,
  Marker,
  GoogleMap,
  useLoadScript,
} from "@react-google-maps/api";
import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Reciver from "../images/icons/reciver.svg";
import Linkforward from "../images/icons/link-forward.svg";
import { toast } from "react-toastify";
import {
  createTemplate,
  updateTemplate,
  getLocTemplateCoordinates,
} from "../services/WhatsappAPI";
import { Spinner } from "react-bootstrap";
import { BasicTemplate } from "../components/template-creation/BasicTemplate";
import { HeaderTemplate } from "../components/template-creation/HeaderTemplate";
import { BodyTemplate } from "../components/template-creation/BodyTemplate";
import { FooterTemplate } from "../components/template-creation/FooterTemplate";
import { ButtonTemplate } from "../components/template-creation/ButtonTemplate";
import { AuthenticationTemplate } from "../components/template-creation/AuthenticationTemplate";
import { AuthenticationPreview } from "../components/template-preview/AuthenticationPreview";
import dummyImgForHeader from "../images/Temp-Image-Placeholder.svg";
import dummyDocumentForHeader from "../images/document-upload.svg";
import dummyLocatImgForHeader from "../images/location-upload.svg";
import dummyVideoImgForHeader from "../images/video-upload.svg";
import { Box, Button as MuiButton, Typography } from "@mui/material";
import getconfig from "../config.js";
import { AuthenticationBuilder, RequestBuilder } from "../utils/templateUtil";
import TemplateVariables from "../components/template-creation/TemplateVariables.jsx";
import Header from "./templates/header.tsx";
import useDeviceWidthDetector from "../components/customHooks/DeviceWidthDetector.jsx";
import pdfIcon from "../images/icons/pdf-icon.svg";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useDispatch, useSelector } from "react-redux";
import { setTemplatesDataInRedux } from "../reduxSlice/templates/index.js";
import "../pdf-worker.js";

const GOOGLE_API_KEY = "AIzaSyASVOh0zf-dgKxPOr4e796luwQzCT150NY";
const libraries = ["places"];

const RenderVideoPlayer = ({ headerData }) => {
  const renderPlayer = useCallback(() => {
    const url = !headerData?.file?.name
      ? headerData?.file
      : URL.createObjectURL(headerData?.file);

    return <ReactPlayer controls url={url} width="100%" height="150px" />;
  }, [headerData]);

  const playerElement = React.useMemo(() => renderPlayer(), [renderPlayer]);

  return (
    <Box className="flex justify-start items-start flex-column bg-[#FFFFFF] p-[10px] rounded-md w-100">
      {playerElement}
    </Box>
  );
};

const Createtemplate = () => {
  const { allTemplates, totalRecords } = useSelector(
    (state) => state.TemplatesReducer
  );
  const dispatch = useDispatch();
  console.log("allTemplates", allTemplates, ":", totalRecords);

  const windowWidth = useDeviceWidthDetector();
  const [selectedAddress, setSelAddress] = useState("");
  const [searchBox, setSearchBox] = useState(null);
  const inputRef = useRef(null);
  const [headerData, setHeaderData] = useState({
    type: "none",
    fileType: "image",
    file: null,
    locationName: "",
    locLongitude: "",
    locLatitude: "",
  });
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [tempImgUrl, setTempImageUrl] = useState(null);
  const [isShowMapModal, setIsShowMapModal] = useState(false);
  const [searchPlace, setSearchPlace] = useState("");
  const [quickReply, setQuickReply] = useState(false);
  const [calltoaction, setCalltoaction] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [pdfViewWidth, setPdfViewWidth] = useState(300);
  const [sending, setSending] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    header: "",
    body: "",
    footer: "",
    category: "UTILITY",
    language: "en_US",
  });
  const [callToActionData, setCallToActionData] = useState([
    {
      key: 1,
      action: "PHONE_NUMBER",
      text: "",
      country: "+92",
      number: "",
      urltype: "",
      websiteurl: "",
    },
  ]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const temp = {
    name: "",
    category: "",
    language: "en_US",
    allow_category_change: true,
  };
  const [quickReplyData, setQuickReplyData] = useState([{ key: 1, data: "" }]);
  const [numQuickReplyButtons, setNumQuickReplyButtons] = useState(1);
  const [numcallToActionButtons, setNumcallToActionButtons] = useState(1);
  const [buttonType, setButtonType] = useState("None");
  const [tempData, setTempData] = useState({
    language: { value: "en_US", label: "English (US)" },
    button: { value: "None", label: "None" },
  });
  const [bodyVarCount, setbodyVarCount] = useState(0);
  const [headerVarCount, setHeaderVarCount] = useState(0);
  const [headerSample, setHeaderSample] = useState("");
  const [bodySample, setBodySample] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [markerPosition, setMarkerPosition] = useState({
    lat: 25.197198381368302,
    lng: 55.27444371451215,
  });
  const [markerPos, setMarkerPos] = useState({
    lat: 25.197198381368302,
    lng: 55.27444371451215,
  });
  const authenticationPreviewForEdit = (template) => {
    const data = {
      id: template.id,
      isApproved: template.status == "APPROVED" ? true : false,
      category: template.category,
      codeExpiry: "",
      copyButton: "",
      footer: "",
      header: "",
      language: template.language,
      name: template.name,
      security: false,
    };
    template.components.map((component) => {
      switch (component.type) {
        case "BODY":
          if (
            component.text.includes(
              "For your security, do not share this code."
            )
          )
            data.security = true;
          break;
        case "FOOTER":
          const inputString = component.text;
          var matchResult = inputString.match(/\b(\d+)\b/);
          if (matchResult && matchResult.length > 0)
            data.codeExpiry = matchResult[1];
          break;
        case "BUTTONS":
          component?.buttons?.map((btn) => {
            switch (btn.type) {
              case "URL":
                data.copyButton = btn?.text;
                break;
            }
          });
      }
    });
    window.history.replaceState({}, document.title);
    setFormData(data);
  };

  // open image in new tab on click
  const openImageInNewTab = (image) => {
    window.open(image, "_blank")?.focus();
  };

  // validating header data
  const validateHeaderData = () => {
    console.log("headerData?.type", headerData?.type);
    if (headerData?.type == "none") {
      return true;
    } else if (headerData?.type == "media") {
      if (headerData?.fileType == "image" && headerData?.file == null) {
        toast.error("Please choose image for header");
        return false;
      } else if (
        headerData?.fileType == "document" &&
        headerData?.file == null
      ) {
        toast.error("Please choose document for header");
        return false;
      } else if (headerData?.fileType == "video" && headerData?.file == null) {
        toast.error("Please choose video for header");
        return false;
      } else if (
        headerData?.fileType == "location" &&
        (headerData?.locationName == "" ||
          headerData?.locLatitude == "" ||
          headerData?.locLongitude == "")
      ) {
        toast.error("Please provide complete data for location in header");
        return false;
      } else {
        return true;
      }
    } else {
      if (formData?.header == "") {
        toast.error("Please write any text in header");
        return false;
      }
      return true;
    }
  };

  // getting location data of template if template is of type location

  const getTemplCoods = async (templateId) => {
    const companyId = localStorage.getItem("companyId");
    const coordinates = await getLocTemplateCoordinates(companyId, templateId);
    console.log(
      "coordinates ",
      coordinates,
      ":::",
      coordinates?.data?.components[0]?.parameters[0]?.location
    );
    setHeaderData((prev) => ({
      ...prev,
      locationName:
        coordinates?.data?.components[0]?.parameters[0]?.location?.name,
      locLatitude:
        coordinates?.data?.components[0]?.parameters[0]?.location?.latitude,
      locLongitude:
        coordinates?.data?.components[0]?.parameters[0]?.location?.longitude,
    }));
  };

  // handle edit workflow
  useEffect(() => {
    if (location.state && location.state.isEdit) {
      setIsEdit(true);
      const template = location.state.template;
      tempData.language = {
        label: template.language == "en_US" ? "English (US)" : "English (UK)",
        value: template.language,
      };
      setTempData(tempData);
      if (template.category == "AUTHENTICATION")
        return authenticationPreviewForEdit(template);
      const data = {
        id: template.id,
        isApproved: template.status == "APPROVED" ? true : false,
        name: template.name,
        category: template.category,
        language: template.language,
        header: "",
        body: "",
        footer: "",
      };
      const quickReplyForEdit = [];
      const callToActionArray = [];
      template.components.map((component) => {
        switch (component.type) {
          case "HEADER":
            data.header = component.text;
            break;
          case "BODY":
            data.body = component.text;
            break;
          case "FOOTER":
            data.footer = component.text;
            break;
          case "BUTTONS":
            component.buttons.map((btn) => {
              const callToAction = {
                key: 1,
                action: "PHONE_NUMBER",
                text: "",
                country: "+92",
                number: "",
                urltype: "",
                websiteurl: "",
              };
              switch (btn.type) {
                case "QUICK_REPLY":
                  quickReplyForEdit.push({
                    key: quickReplyForEdit.length + 1,
                    data: btn.text,
                  });
                  break;
                case "PHONE_NUMBER":
                  callToAction.key = callToActionArray.length + 1;
                  callToAction.action = "PHONE_NUMBER";
                  callToAction.text = btn.text;
                  callToAction.number = btn.phone_number;
                  callToActionArray.push(callToAction);
                  break;
                case "URL":
                  callToAction.key = callToActionArray.length + 1;
                  callToAction.action = "visitwebsite";
                  callToAction.urltype = btn.type;
                  callToAction.text = btn.text;
                  callToAction.websiteurl = btn.url;
                  callToActionArray.push(callToAction);
                  break;
              }
            });
            break;
        }
      });
      window.history.replaceState({}, document.title);
      if (quickReplyForEdit.length > 0) {
        setButtonType("quickreply");
        setQuickReplyData(quickReplyForEdit);
        setNumQuickReplyButtons(quickReplyForEdit.length);
        tempData.button = { value: "quickreply", label: "Quick Reply" };
        setTempData(tempData);
      } else if (callToActionArray.length > 0) {
        setButtonType("calltoaction");
        setCallToActionData(callToActionArray);
        setNumcallToActionButtons(callToActionArray.length);
        tempData.button = { value: "calltoaction", label: "Call To Action" };
        setTempData(tempData);
      }

      console.log("got data of template : ", template);

      setHeaderData({
        type:
          template?.components[0]?.format?.toLowerCase() == "image" ||
          template?.components[0]?.format?.toLowerCase() == "document" ||
          template?.components[0]?.format?.toLowerCase() == "video" ||
          template?.components[0]?.format?.toLowerCase() == "location"
            ? "media"
            : template?.components[0]?.format?.toLowerCase() == "text"
            ? "text"
            : "none",
        fileType: template?.components[0]?.format?.toLowerCase(),
        file:
          template?.components[0]?.example?.header_handle &&
          template?.components[0]?.example?.header_handle[0]
            ? template?.components[0]?.example?.header_handle[0]
            : null,
        locationName: "",
        locLongitude: "",
        locLatitude: "",
      });

      if (template?.components[0]?.format?.toLowerCase() == "location") {
        getTemplCoods(template?.id);
      }

      if (
        template?.components[0]?.example?.header_handle &&
        template?.components[0]?.example?.header_handle[0] &&
        template?.components[0]?.format?.toLowerCase() == "image"
      ) {
        setTempImageUrl(template?.components[0]?.example?.header_handle[0]);
      }

      if (
        template?.components[1]?.example?.body_text &&
        Object?.values(template?.components[1]?.example?.body_text[0])
      ) {
        setbodyVarCount(
          Object?.values(template?.components[1]?.example?.body_text[0])?.length
        );
        let sampleArray = [];
        for (
          let i = 0;
          i !=
          Object?.values(template?.components[1]?.example?.body_text[0])
            ?.length;
          i++
        ) {
          sampleArray.push(
            Object?.values(template?.components[1]?.example?.body_text[0])[i]
          );
        }
        setBodySample(sampleArray);
      }

      setFormData(data);
    }
  }, []);

  const getUserCurrentLoc = () => {
    //if (selectedAddress == "") {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successPosition, error);
    } else {
      console.log("Geolocation not supported");
    }
    //}
  };

  // getting document total pages
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  async function successPosition(position) {
    console.log("position : ", position);
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    setMarkerPosition({
      lat: latitude,
      lng: longitude,
    });
    setMarkerPos({
      lat: latitude,
      lng: longitude,
    });
    const geocoder = new window.google.maps.Geocoder();
    const response = await geocoder.geocode({
      location: { lat: latitude, lng: longitude },
    });
    if (response?.results) {
      console.log("name of location : ", response.results[0]);
      setSelAddress(response.results[0]?.formatted_address);
    }
  }

  function error() {
    console.log("Unable to retrieve your location");
  }

  const getPreviewHeight = () => {
    const previewSection = document.getElementsByClassName("heloooo");
    console.log("off set width : ", previewSection[0]?.clientWidth);
    setPdfViewWidth(previewSection[0]?.clientWidth);
  };
  // getting message preview width for pdf view
  useEffect(() => {
    getPreviewHeight();
  }, [windowWidth]);

  useEffect(() => {
    getPreviewHeight();
  }, [headerData.file]);

  useEffect(() => {
    if (formData?.header?.indexOf("{{1}}") === -1) {
      // setHeaderVarCount(0);
      // setHeaderSample('')
    }
    if (
      bodyVarCount > 0 &&
      formData?.body?.indexOf("{{" + bodyVarCount + "}}") === -1
    ) {
      // const updatedBodyVars = [...bodySample];
      // updatedBodyVars.pop();
      // setBodySample(updatedBodyVars);
      // setbodyVarCount(prevbodyVarCount => prevbodyVarCount - 1)
    }
  }, [formData]);

  useEffect(() => {
    if (
      buttonType !== "quickreply" &&
      !(location.state && location.state.isEdit)
    ) {
      setQuickReplyData([{ key: 1, data: "" }]);
      setNumQuickReplyButtons(1);
    }
  }, [buttonType]);

  useEffect(() => {
    if (
      buttonType !== "calltoaction" &&
      !(location.state && location.state.isEdit)
    ) {
      setCallToActionData([
        {
          key: 1,
          action: "PHONE_NUMBER",
          text: "",
          country: "+92",
          number: "",
          urltype: "",
          websiteurl: "",
        },
      ]);
      setNumcallToActionButtons(1);
    }
  }, [buttonType]);

  function removeWordsWithBraces(str) {
    return str.replace(/{{[^{}]*}}/g, "");
  }

  function hasWordsWithBraces(str) {
    const regex = /{{[^{}]*}}/;
    return regex.test(str);
  }

  function handleChange(event) {
    // if (event.target.name == "media") {
    //     setFormData(prevFormData => {
    //         return {
    //             ...prevFormData,
    //             [event.target.name]: event.target.files[0]
    //         }
    //     })
    // } else

    if (event.target.name == "security") {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          [event.target.name]: event.target.checked,
        };
      });
    } else {
      if (event.target.name == "codeExpiry" && event.target.value < 0) {
        return false;
      }

      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          [event.target.name]: event.target.value,
        };
      });
    }

    if (event.target.name == "header") {
      if (hasWordsWithBraces(event.target.value)) {
        toast.error("Adding Variables in header not supported");
      }
      let filteredText = removeWordsWithBraces(event.target.value);
      console.log(
        "removeWordsWithBraces",
        removeWordsWithBraces(event.target.value),
        "::",
        filteredText
      );
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          [event.target.name]: filteredText,
        };
      });
    }
  }

  function handleLanguage(selectedOption) {
    setTempData({ ...tempData, language: selectedOption });
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        language: selectedOption.value,
      };
    });
  }

  function handleButton(selectedOption) {
    setTempData({ ...tempData, button: selectedOption });
    setButtonType(selectedOption.value);
  }

  const handleHeaderVarCount = () => {
    setHeaderVarCount((prevHeaderVarCount) => prevHeaderVarCount + 1);
    setFormData((prevFormData) => ({
      ...prevFormData,
      header: prevFormData.header + "{{" + (headerVarCount + 1) + "}}",
    }));
    return;
  };

  const handlebodyVarCount = () => {
    setbodyVarCount((prevbodyVarCount) => prevbodyVarCount + 1);
    // setFormData(prevFormData => ({
    //     ...prevFormData,
    //     body: prevFormData.body + "{{" + (bodyVarCount + 1) + "}}"
    // }))
    setBodySample((prevBodyVars) => [...prevBodyVars, ""]);
  };

  // checking if header contains more than one variable
  const checkHeaderVariablesCount = () => {
    if (formData?.header?.length == 0) {
      return true;
    }

    let count = 0;
    for (let i = 0; i < bodyVarCount; i++) {
      let matchedIndex = formData?.header?.indexOf("{{" + (i + 1) + "}}");
      if (count > 1) {
        break;
      }
      if (matchedIndex > -1) {
        count += 1;
      }
    }

    if (count > 1) {
      toast.error("Can not add more than 1 variable in header");
      return false;
    }

    return true;
  };

  // checking if body contains variable
  const checkIfBodyHasVariables = () => {
    let count = 0;
    for (let i = 0; i < bodyVarCount; i++) {
      let matchedIndex = formData?.body?.indexOf("{{" + (i + 1) + "}}");
      if (count > 0) {
        break;
      }
      if (matchedIndex > -1) {
        count += 1;
      }
    }

    if (count > 0) {
      return true;
    }

    return false;
  };

  // checking if body header variable
  const checkIfHeaderHasVariables = () => {
    return false;
    if (formData?.header?.length == 0) {
      return false;
    }
    let count = 0;
    for (let i = 0; i < bodyVarCount; i++) {
      let matchedIndex = formData?.header?.indexOf("{{" + (i + 1) + "}}");
      if (count > 0) {
        break;
      }
      if (matchedIndex > -1) {
        count += 1;
      }
    }

    if (count > 0) {
      return true;
    }

    return false;
  };

  // getting header variable value
  const getHeaderVarValue = () => {
    if (bodySample?.length == 0) {
      return "";
    }
    let name = "";
    for (let i = 0; i < bodyVarCount; i++) {
      let matchedIndex = formData?.header?.indexOf("{{" + (i + 1) + "}}");
      if (matchedIndex > -1) {
        name = bodySample[i];
      }
    }

    return [name];
  };

  // checking if all variables have been assigned values
  const checkIfAllVarsValuesAdded = () => {
    let isEmpty = false;
    for (let i = 0; i != bodyVarCount; i++) {
      if (bodySample[i] == "") {
        isEmpty = true;
        break;
      }
    }

    if (isEmpty) {
      toast.error("Please provide values for all variables you have added");
      return false;
    }

    return true;
  };

  // removing variables
  const removeVariable = (index) => {
    let tempCount = bodyVarCount;
    let prevFormData = formData;
    const isLastItem = index + 1 == bodyVarCount;

    // replacing matched variables with empty space in body
    if (prevFormData?.body?.length > 0) {
      console.log("index", index);
      let updatedBodyData = prevFormData?.body
        ?.toString()
        .replace("{{" + (index + 1) + "}}", "");
      prevFormData.body = updatedBodyData;
      console.log("updatedBodyData", prevFormData.body);
    }

    // replacing matched variables with empty space in header
    // if (prevFormData?.header?.length > 0) {
    //     let updatedHeaderData = prevFormData?.header?.toString().replace("{{" + (index + 1) + "}}", "")
    //     prevFormData.header = updatedHeaderData
    // }

    // updating body variables count
    for (let i = index; i < tempCount; i++) {
      let matchedIndex = prevFormData.body?.indexOf("{{" + (i + 1) + "}}");
      if (matchedIndex > -1 && i > 0) {
        if (!isLastItem) {
          let tempBodyData = prevFormData.body
            ?.toString()
            .replace("{{" + (i + 1) + "}}", "{{" + i + "}}");
          console.log(
            "updated data =======",
            "{{" + (i + 1) + "}}",
            ":::",
            tempBodyData
          );
          prevFormData.body = tempBodyData;
        } else {
          if (i + 1 == index + 1 && isLastItem) {
            let tempBodyData = prevFormData.body
              ?.toString()
              .replace("{{" + (i + 1) + "}}", "{{" + (i + 2) + "}}");
            prevFormData.body = tempBodyData;
          }
        }
      }
    }

    // updating header variables count
    // for (let i = 0; i < tempCount; i++) {
    //     let matchedIndex = prevFormData.header?.indexOf("{{" + (i + 1) + "}}")
    //     if (matchedIndex > -1 && i > 0) {
    //         if (!isLastItem) {
    //             let tempBodyData = prevFormData.header?.toString().replace("{{" + (i + 1) + "}}", "{{" + (i) + "}}")
    //             prevFormData.header = tempBodyData
    //         } else {
    //             if ((i + 1) == (index + 1) && isLastItem) {
    //                 let tempBodyData = prevFormData.header?.toString().replace("{{" + (i + 1) + "}}", "{{" + (i + 2) + "}}")
    //                 prevFormData.header = tempBodyData
    //             }
    //         }
    //     }
    // }

    setFormData((prev) => ({
      ...prev,
      ...prevFormData,
    }));

    setbodyVarCount((prevbodyVarCount) => prevbodyVarCount - 1);
    const updatedBodyVars = [...bodySample];
    updatedBodyVars.splice(index, 1);
    setBodySample(updatedBodyVars);
  };

  const handleCallToAction = (event, dataKey) => {
    if (event.target.name === "action") {
      const selectedValue = event.target.value;

      // Update selectedOptions state
      setSelectedOptions((prevSelectedOptions) => {
        const updatedSelected = prevSelectedOptions.filter((option) => {
          // Remove the old value from selectedOptions if it was previously selected
          const currentItem = callToActionData.find(
            (item) => item.key === dataKey
          );
          return currentItem && currentItem.action !== option;
        });

        return [...updatedSelected, selectedValue];
      });
    }
    setCallToActionData((prevData) => {
      return prevData.map((item) => {
        if (item.key === dataKey) {
          return {
            ...item,
            [event.target.name]: event.target.value,
          };
        }
        return item;
      });
    });
  };

  const toggleAction = (dataKey) => {
    if (numcallToActionButtons > 1) {
      setCallToActionData((prevData) => {
        const data = [...prevData];
        const action1 = data.find((d) => d.key === 1).action;
        const action2 = data.find((d) => d.key === 2).action;
        if (dataKey === 2) {
          data.find((d) => d.key === 1).action =
            action2 === "PHONE_NUMBER" ? "visitwebsite" : "PHONE_NUMBER";
        }
        if (dataKey === 1) {
          data.find((d) => d.key === 2).action =
            action1 === "PHONE_NUMBER" ? "visitwebsite" : "PHONE_NUMBER";
        }
        return data;
      });
    }
  };

  const AddCallToActionButton = () => {
    if (numcallToActionButtons < 2) {
      setNumcallToActionButtons(
        (prevNumcallToActionButtons) => prevNumcallToActionButtons + 1
      );
      setCallToActionData([
        ...callToActionData,
        {
          key: numcallToActionButtons + 1,
          action:
            callToActionData[callToActionData.length - 1].action ===
            "PHONE_NUMBER"
              ? "visitwebsite"
              : "PHONE_NUMBER",
          text: "",
          country: "+92",
          number: "",
          urltype: "",
          websiteurl: "",
        },
      ]);
    }
  };

  const deleteCallToAction = (dataKey) => {
    setSelectedOptions((prevSelectedOptions) => {
      const deletedItem = callToActionData.find((item) => item.key === dataKey);
      return prevSelectedOptions.filter(
        (option) => option !== deletedItem?.action
      );
    });
    if (numcallToActionButtons > 1) {
      setCallToActionData((prevState) =>
        prevState.filter((item) => item.key !== dataKey)
      );

      setCallToActionData((prevData) =>
        prevData.map((item) => {
          if (item.key > dataKey) {
            return { ...item, key: item.key - 1 };
          }
          return item;
        })
      );
      setNumcallToActionButtons(
        (prevNumcallToActionButtons) => prevNumcallToActionButtons - 1
      );
    }
  };

  const handleQuickReply = (event, dataKey) => {
    setQuickReplyData((prevData) => {
      return prevData.map((item) => {
        if (item.key === dataKey) {
          return { ...item, data: event.target.value };
        }
        return item;
      });
    });
  };

  const AddQuickReplyButton = () => {
    if (numQuickReplyButtons < 3) {
      setNumQuickReplyButtons(
        (prevNumQuickReplyButtons) => prevNumQuickReplyButtons + 1
      );
      setQuickReplyData([
        ...quickReplyData,
        { key: numQuickReplyButtons + 1, data: "" },
      ]);
    }
  };

  const deleteQuickReply = (dataKey) => {
    if (numQuickReplyButtons > 1) {
      setQuickReplyData((prevState) =>
        prevState.filter((item) => item.key !== dataKey)
      );

      setQuickReplyData((prevData) =>
        prevData.map((item) => {
          if (item.key > dataKey) {
            return { ...item, key: item.key - 1 };
          }
          return item;
        })
      );

      setNumQuickReplyButtons(
        (prevNumQuickReplyButtons) => prevNumQuickReplyButtons - 1
      );
    }
  };

  function isLowerCaseAndUnderscores(str) {
    return /^[a-z_]+$/.test(str);
  }
  function isNumber(str) {
    return /^\d+$/.test(str);
  }

  const validateCharacterLimit = () => {
    if (
      (formData.name && formData.name.length > 60) ||
      (formData.header && formData.header.length > 60) ||
      (formData.body && formData.body.length > 1024) ||
      (formData.footer && formData.footer.length > 60)
    ) {
      toast.warning("Address the issue before proceeding further", {
        autoClose: 1500,
      });
      return false;
    }
    console.log("validation if limit passed");
    return true;
  };

  const validateForm = () => {
    let error = 0;
    let errormessage = "";
    if (error === 0 && formData.name === "") {
      errormessage = "Name cannot be empty";
      error = 1;
    }
    let x = isLowerCaseAndUnderscores(formData.name);
    if (error === 0 && x === false) {
      errormessage = "Name can only have lowercase and underscores";
      error = 1;
    }
    if (
      error === 0 &&
      (formData.header?.startsWith("{{1}}") ||
        formData.header?.endsWith("{{1}}"))
    ) {
      errormessage = "Variables cannot be at the start or end of header";
      error = 1;
    }
    if (
      error === 0 &&
      (formData.body.startsWith("{{1}}") ||
        formData.body.startsWith("{{" + bodyVarCount + "}}") ||
        formData.body.endsWith("{{" + bodyVarCount + "}}"))
    ) {
      errormessage = "Variables cannot be at the start or end of body";
      error = 1;
    }
    if (
      error === 0 &&
      formData.body === "" &&
      formData.category !== "AUTHENTICATION"
    ) {
      errormessage = "Body cannot be empty";
      error = 1;
    }
    if (buttonType === "quickreply") {
      quickReplyData.map((item) => {
        if (item.data === "") {
          errormessage = "Button text cannot be empty";
          error = 1;
        }
      });
    }
    if (buttonType === "calltoaction") {
      callToActionData.map((item) => {
        if (item.text === "") {
          errormessage = "Button text cannot be empty";
          error = 1;
        }
        if (item.action === "PHONE_NUMBER") {
          if (error === 0 && item.number === "") {
            errormessage = "Phone number cannot be empty";
            error = 1;
          }
          let y = isNumber(item.number.substring(1, item.number.length));
          if (error === 0 && y === false) {
            errormessage = "Phone number can only be numeric";
            error = 1;
          }
        }
        if (item.action === "visitwebsite") {
          if (error === 0 && item.websiteurl === "") {
            errormessage = "URL cannot be empty";
            error = 1;
          }
        }
      });
    }

    if (error === 1) {
      toast.error(errormessage, { autoClose: 1500 });
      return false;
    }
    console.log("Template Submitted Sucessfully");
    return true;
  };

  const authenticationRequest = () => {
    const request = {
      ...temp,
      components: [
        {
          type: "body",
          add_security_recommendation: formData.security
            ? formData.security
            : false,
        },
        {
          type: "footer",
          code_expiration_minutes: formData.codeExpiry,
        },
        {
          type: "buttons",
          buttons: [
            {
              type: "otp",
              otp_type: "copy_code",
              text: formData.copyButton ?? "",
            },
          ],
        },
      ],
    };
    return request;
  };
  const updateRequest = async () => {
    if (
      !validateForm() ||
      !validateCharacterLimit() ||
      !validateHeaderData() ||
      !checkHeaderVariablesCount() ||
      !checkIfAllVarsValuesAdded()
    ) {
      return;
    }
    let doesBodyHasVars = checkIfBodyHasVariables();
    let doesHeaderHasVars = checkIfHeaderHasVariables();
    setSending(true);
    let request = {};
    let data = new FormData();
    if (formData.category === "AUTHENTICATION") {
      request = new AuthenticationBuilder(location.state.template, formData)
        .withBody()
        .withFooter()
        .withButtons()
        .build();
    } else {
      request = new RequestBuilder(location.state.template, formData)
        .withHeader(
          headerData?.type,
          headerData?.fileType,
          doesHeaderHasVars,
          getHeaderVarValue()
        )
        .withBody(doesBodyHasVars, [bodySample])
        .withFooter()
        .withButtons(
          buttonType,
          buttonType === "calltoaction" ? callToActionData : quickReplyData
        )
        .build();
    }
    request.id = location.state.template.id;
    if (location.state.template.status !== "APPROVED") {
      request.category = formData.category;
      request.allow_category_change = true;
    }

    console.log("headerData.file?.name", headerData.file?.name);
    if (
      headerData.fileType == "image" ||
      headerData.fileType == "video" ||
      headerData.fileType == "document"
    ) {
      if (headerData.file && headerData.file?.name) {
        // for appending file
        data.append("media", headerData.file);
        data.append("hasMedia", true);
      }
    }

    // for location
    if (headerData.fileType == "location") {
      data.append(
        "location",
        JSON.stringify({
          latitude: headerData?.locLatitude,
          longitude: headerData?.locLongitude,
          name: headerData?.locationName,
          address: headerData?.locationName,
        })
      );
    }

    data.append("template", JSON.stringify(request));
    console.log("data final ", request, ":", data.get("media"));

    const res = await updateTemplate(data);
    if (res.success) {
      setSuccess(true);
      toast.success("Template Updated");
      setTimeout(() => {
        navigate("/template");
      }, 2000);
    } else {
      toast.error(res.error);
    }
    setSending(false);
    //console.log(res)
  };

  const createRequest = async () => {
    if (
      !validateForm() ||
      !validateCharacterLimit() ||
      !validateHeaderData() ||
      !checkHeaderVariablesCount() ||
      !checkIfAllVarsValuesAdded()
    ) {
      return;
    }
    setSending(true);
    let doesBodyHasVars = checkIfBodyHasVariables();
    let doesHeaderHasVars = checkIfHeaderHasVariables();
    temp.name = formData.name;
    temp.category = formData.category;
    temp.language = formData.language;
    let request;
    if (formData.category == "AUTHENTICATION")
      request = authenticationRequest(temp);
    else {
      let bodyData = {};
      if (doesBodyHasVars) {
        bodyData = {
          type: "BODY",
          text: formData.body,
          example: {
            body_text: [bodySample],
          },
        };
      } else {
        bodyData = { type: "BODY", text: formData.body };
      }

      let tempHeaderData = {};
      if (doesHeaderHasVars) {
        tempHeaderData = {
          type: "HEADER",
          format: "text",
          text: formData.header,
          example: {
            header_text: getHeaderVarValue(),
          },
        };
      } else {
        tempHeaderData = {
          type: "HEADER",
          format: "text",
          text: formData.header,
        };
      }

      request = {
        ...temp,
        allow_category_change: true,
        components: [
          bodyData,
          headerData?.type == "text"
            ? tempHeaderData
            : headerData?.type == "media"
            ? headerData?.fileType == "image"
              ? {
                  type: "HEADER",
                  format: "image",
                  example: { header_handle: ["4::aW..."] },
                }
              : headerData?.fileType == "video"
              ? {
                  type: "HEADER",
                  format: "video",
                  example: { header_handle: ["4::aW..."] },
                }
              : headerData?.fileType == "document"
              ? {
                  type: "HEADER",
                  format: "document",
                  example: { header_handle: ["4::aW..."] },
                }
              : { type: "HEADER", format: "location" }
            : {},
          formData.footer !== ""
            ? { type: "FOOTER", text: formData.footer }
            : {},
          buttonType === "calltoaction"
            ? {
                type: "BUTTONS",
                buttons: callToActionData.map((item) => {
                  if (item.action === "PHONE_NUMBER") {
                    return {
                      type: "PHONE_NUMBER",
                      text: item.text,
                      phone_number: item.number,
                    };
                  } else if (item.action === "visitwebsite") {
                    return {
                      type: "url",
                      text: item.text,
                      url: item.websiteurl,
                    };
                  }
                }),
              }
            : {},
          buttonType === "quickreply"
            ? {
                type: "BUTTONS",
                buttons: quickReplyData.map((item) => ({
                  type: "QUICK_REPLY",
                  text: item.data,
                })),
              }
            : {},
        ].filter((c) => Object.keys(c).length !== 0),
      };
    }
    let data = new FormData();
    if (
      headerData.fileType == "image" ||
      headerData.fileType == "video" ||
      headerData.fileType == "document"
    ) {
      if (headerData.file && headerData?.file?.name) {
        // for appending file
        data.append("media", headerData.file);
        data.append("hasMedia", true);
      }
    }

    // for location
    if (headerData.fileType == "location") {
      data.append(
        "location",
        JSON.stringify({
          latitude: headerData?.locLatitude,
          longitude: headerData?.locLongitude,
          name: headerData?.locationName,
          address: headerData?.locationName,
        })
      );
    }

    data.append("template", JSON.stringify(request));
    console.log("data final ", request, ":", data.get("media"));

    // setSending(false)
    // return

    const res = await createTemplate(data);

    // console.log(request)
    // const res = await createTemplate(request)
    if (res.success) {
      setSuccess(true);
      toast.success("Template created");

      //dispatch(setTemplatesDataInRedux([res?.data, ...allTemplates]))

      setTimeout(() => {
        navigate("/template", { state: { isRefetchData: true } });
      }, 2000);
    } else {
      toast.error(res.error);
    }
    setSending(false);
    console.log(res);
  };

  const handleHeaderSample = (e) => {
    setHeaderSample(e.target.value);
  };

  const handleBodySample = (event, index) => {
    const updatedBodyVars = [...bodySample];
    updatedBodyVars[index] = event.target.value;
    setBodySample(updatedBodyVars);
  };

  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const containerStyleForPreview = {
    width: "100%",
    height: "150px",
  };

  const options = {
    mapTypeControl: false, // Disable Map and Satellite options
    streetViewControl: false, // Disable Pegman (Street View)
    zoomControl: false, // Disable Zoom in/out controls
    fullscreenControl: false, // Disable Fullscreen control
  };

  const handelClickOnMap = async (data) => {
    console.log("data received on click", data);
    setMarkerPosition({ lat: data?.latLng?.lat(), lng: data?.latLng?.lng() });

    // Geocode the clicked location to get the address
    const geocoder = new window.google.maps.Geocoder();
    const response = await geocoder.geocode({
      location: { lat: data?.latLng?.lat(), lng: data?.latLng?.lng() },
    });
    if (response?.results) {
      console.log("name of location : ", response.results[0]);
      setSelAddress(response.results[0]?.formatted_address);
    }
  };

  const onLoadSearchField = (ref) => {
    setSearchBox(ref);
  };

  const onPlacesChanged = async () => {
    const places = searchBox.getPlaces();
    if (places && places.length > 0) {
      setMarkerPosition({
        lat: places[0]?.geometry.location.lat(),
        lng: places[0]?.geometry.location.lng(),
      });
      setSelAddress(places[0]?.formatted_address);
      console.log(
        "places",
        places,
        ":::",
        places[0]?.geometry.location.lat(),
        ":",
        places[0]?.geometry.location.lng()
      );
    }
  };

  const handleSetLocation = () => {
    setHeaderData((prev) => ({
      ...prev,
      locationName: selectedAddress,
      locLatitude: markerPosition?.lat,
      locLongitude: markerPosition?.lng,
    }));
    setIsShowMapModal(false);
  };

  const { isLoaded: isGoogleLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: libraries,
  });

  if (!isGoogleLoaded) {
    return (
      <Box className="bg-[#F3F7F7] flex flex-column justify-start align-items-start pb-6">
        <Header isEdit={isEdit} />
        <div
          style={{
            display: "flex",
            width: "80%",
            marginLeft: "10%",
            marginTop: "50px",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div className="user-personal-info" style={{ width: "100%" }}>
            <div className="info-row">
              <div className="placeholder-glow" style={{ maxWidth: "100%" }}>
                <span
                  className="col-12 placeholder mb-2 py-2"
                  style={{ height: "70vh" }}
                ></span>
              </div>
            </div>
          </div>
        </div>
      </Box>
    );
  }

  // getting size of file
  function formatFileSize(sizeInBytes) {
    const sizeInMB = sizeInBytes / (1024 * 1024);
    const sizeInKB = sizeInBytes / 1024;

    if (sizeInMB >= 1) {
      return `${sizeInMB.toFixed(2)} MB`;
    } else {
      return `${sizeInKB.toFixed(2)} KB`;
    }
  }

  const handleViewFile = () => {
    if (headerData?.file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newWindow = window.open();
        if (newWindow) {
          newWindow.document.write(
            `<iframe src="${e.target.result}" frameborder="0" style="border:0; width:100%; height:100%;" allowfullscreen></iframe>`
          );
          newWindow.document.title = headerData?.file.name;
        }
      };
      reader.readAsDataURL(headerData?.file);
    } else {
      alert("No file selected");
    }
  };
  console.log("formDataaa", formData);
  return (
    <>
      <Box className="bg-[#F3F7F7] flex flex-column justify-start align-items-start pb-6">
        <Header isEdit={isEdit} />
        <Container className="mt-[18px]">
          <Row>
            <Col md={8} className="mb-3 mb-md-0">
              <BasicTemplate
                isEdit={isEdit}
                handleChange={handleChange}
                formData={formData}
                handleLanguage={handleLanguage}
                tempData={tempData}
              />
              {formData.category !== "AUTHENTICATION" && (
                <HeaderTemplate
                  setSelAddress={setSelAddress}
                  showMapModal={() => {
                    setIsShowMapModal(true);
                    getUserCurrentLoc();
                  }}
                  setFormData={setFormData}
                  setTempImageUrl={(val) => setTempImageUrl(val)}
                  setHeaderData={setHeaderData}
                  headerData={headerData}
                  tempImgUrl={tempImgUrl}
                  formData={formData}
                  handleChange={handleChange}
                  handleHeaderSample={handleHeaderSample}
                  handleHeaderVarCount={handleHeaderVarCount}
                  headerSample={headerSample}
                  headerVarCount={headerVarCount}
                />
              )}
              {formData.category !== "AUTHENTICATION" && (
                <BodyTemplate
                  handleChange={handleChange}
                  formData={formData}
                  handlebodyVarCount={handlebodyVarCount}
                  bodySample={bodySample}
                  bodyVarCount={bodyVarCount}
                  handleBodySample={handleBodySample}
                />
              )}
              {formData.category !== "AUTHENTICATION" && (
                <TemplateVariables
                  removeVariable={(ind) => removeVariable(ind)}
                  handlebodyVarCount={handlebodyVarCount}
                  bodySample={bodySample}
                  bodyVarCount={bodyVarCount}
                  handleBodySample={handleBodySample}
                />
              )}
              {formData.category !== "AUTHENTICATION" && (
                <FooterTemplate
                  handleChange={handleChange}
                  formData={formData}
                />
              )}
              {formData.category !== "AUTHENTICATION" && (
                <ButtonTemplate
                  tempData={tempData}
                  handleButton={handleButton}
                  buttonType={buttonType}
                  quickReplyData={quickReplyData}
                  handleQuickReply={handleQuickReply}
                  handleCallToAction={handleCallToAction}
                  selectedOptions={selectedOptions}
                  deleteQuickReply={deleteQuickReply}
                  numQuickReplyButtons={numQuickReplyButtons}
                  numcallToActionButtons={numcallToActionButtons}
                  AddQuickReplyButton={AddQuickReplyButton}
                  AddCallToActionButton={AddCallToActionButton}
                  callToActionData={callToActionData}
                  deleteCallToAction={deleteCallToAction}
                  toggleAction={toggleAction}
                />
              )}
              {formData.category == "AUTHENTICATION" && (
                <AuthenticationTemplate
                  formData={formData}
                  handleChange={handleChange}
                />
              )}
              {success && (
                <Row>
                  <Col sm={12}>
                    <div
                      style={{
                        margin: "20px 5px",
                        color: "#165E5B",
                        fontWeight: "500",
                      }}
                    >
                      Template created successfully
                    </div>
                  </Col>
                </Row>
              )}
              <Row className="pt-3">
                <Col sm={12} className="text-end">
                  {isEdit ? (
                    <Box className="flex justify-start items-start mt-[18px] w-100 d-none d-md-block">
                      <MuiButton
                        disabled={sending}
                        onClick={updateRequest}
                        variant="contained"
                        disableRipple
                        sx={{
                          background: "#165E5B",
                          color: "#FFFFFF",
                          borderRadius: "5px",
                          width: "100%",
                          height: "45px",
                          textTransform: "none",
                          boxShadow: "none",
                          fontWeight: 600,
                          fontSize: "16px",
                          fontFamily: "Segoe UI",
                          "&:hover": { backgroundColor: "#165E5B" },
                        }}
                      >
                        {sending ? <Spinner size="sm" /> : "Update Template"}
                      </MuiButton>
                    </Box>
                  ) : (
                    <Box className="flex justify-start items-start mt-[18px] w-100 d-none d-md-block ">
                      <MuiButton
                        onClick={createRequest}
                        variant="contained"
                        disabled={sending}
                        disableRipple
                        sx={{
                          background: "#165E5B",
                          color: "#FFFFFF",
                          borderRadius: "5px",
                          width: "100%",
                          height: "45px",
                          textTransform: "none",
                          boxShadow: "none",
                          fontWeight: 600,
                          fontSize: "16px",
                          fontFamily: "Segoe UI",
                          "&:hover": { backgroundColor: "#165E5B" },
                        }}
                      >
                        {sending ? <Spinner size="sm" /> : "Create Template"}
                      </MuiButton>
                    </Box>
                  )}
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              {/* <div className="template-header mb-3">
                                    <div className="template-heading">
                                        Preview
                                    </div>
                                </div> */}
              {formData.category == "AUTHENTICATION" && (
                <AuthenticationPreview formData={formData} />
              )}
              {formData.category !== "AUTHENTICATION" && (
                <div
                  className="preview-sec"
                  style={{ padding: "20px", paddingTop: "0px" }}
                >
                  <Box
                    className={`flex justify-center items-center w-100  `}
                    sx={{ paddingTop: "16px" }}
                  >
                    <h4
                      style={{ fontFamily: "SEGOE UI" }}
                      className="text-[16px] font-medium leading-[22px] text-[#000000] mob-heading text-center py-1"
                    >
                      Message preview on WhatsApp
                    </h4>
                  </Box>
                  <div className="preview-body mt-[10px]">
                    {headerData?.type == "media" ? (
                      <Box className="flex justify-start items-start flex-column bg-[#FFFFFF] p-[10px] rounded-md w-100">
                        {headerData?.fileType == "location" &&
                        headerData?.locationName != "" &&
                        headerData?.locLatitude != "" &&
                        headerData?.locLongitude != "" ? (
                          <GoogleMap
                            center={{
                              lat: Number(headerData?.locLatitude),
                              lng: Number(headerData?.locLongitude),
                            }}
                            zoom={15}
                            mapContainerStyle={containerStyleForPreview}
                            options={options}
                          >
                            <Marker
                              position={{
                                lat: Number(headerData?.locLatitude),
                                lng: Number(headerData?.locLongitude),
                              }}
                            />
                          </GoogleMap>
                        ) : headerData?.file &&
                          headerData?.fileType == "image" ? (
                          <img
                            src={
                              !headerData?.file?.name
                                ? headerData?.file
                                : URL.createObjectURL(headerData?.file)
                            }
                            style={{
                              width: "100%",
                              // height: "150px",
                              maxHeight: "400px",
                              objectFit: "contain",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              openImageInNewTab(
                                !headerData?.file?.name
                                  ? headerData?.file
                                  : URL.createObjectURL(headerData?.file)
                              )
                            }
                          />
                        ) : headerData?.fileType == "video" &&
                          headerData?.file ? (
                          <>
                            <RenderVideoPlayer headerData={headerData} />
                            {/* <ReactPlayer controls url={!headerData?.file?.name ? headerData?.file : URL.createObjectURL(headerData?.file)} width="100%" height={"150px"} /> */}
                          </>
                        ) : headerData?.fileType == "document" &&
                          headerData?.file ? (
                          <>
                            {headerData?.file?.name ? (
                              <div
                                style={{
                                  display: "flex",
                                  background: "#BCF1D5",
                                  padding: "10px",
                                  borderRadius: "2px",
                                  width: "100%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  style={{
                                    maxHeight: "140px",
                                    overflow: "hidden",
                                    width: "100%",
                                  }}
                                  className="heloooo"
                                >
                                  {/* <Document file={headerData?.file} onLoadSuccess={onDocumentLoadSuccess} >
                                                                                            <Page pageNumber={1}
                                                                                                renderAnnotationLayer={false}
                                                                                                renderTextLayer={false}
                                                                                                width={pdfViewWidth}
                                                                                            />
                                                                                        </Document> */}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    width: "100%",
                                    gap: "10px",
                                  }}
                                  onClick={handleViewFile}
                                >
                                  <img
                                    src={pdfIcon}
                                    style={{ width: "32px", height: "38px" }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "flex-start",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontWeight: 500,
                                        fontSize:
                                          windowWidth > 1024 ? "14px" : "12px",
                                        fontFamily: "Segoe UI",
                                        color: "#000000",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Preview File
                                    </p>
                                    {/* <p style={{ fontWeight: 500, fontSize: windowWidth > 1024 ? "14px" : "12px", fontFamily: "Segoe UI", color: "rgba(0, 0, 0, 0.6)" }} >{numPages} pages - PDF - {templateData?.header?.example?.header_handle[0].size}</p> */}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    background: "#BCF1D5",
                                    padding: "10px",
                                    borderRadius: "2px",
                                    width: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    style={{
                                      maxHeight: "140px",
                                      overflow: "hidden",
                                      width: "100%",
                                    }}
                                    className="heloooo"
                                  >
                                    {/* <Document file={{ url: headerData?.file }} onLoadSuccess={onDocumentLoadSuccess} >
                                                                                                <Page pageNumber={1}
                                                                                                    renderAnnotationLayer={false}
                                                                                                    renderTextLayer={false}
                                                                                                    width={pdfViewWidth}
                                                                                                />
                                                                                            </Document> */}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      width: "100%",
                                      gap: "10px",
                                    }}
                                    onClick={() =>
                                      window
                                        .open(headerData?.file, "_blank")
                                        ?.focus()
                                    }
                                  >
                                    <img
                                      src={pdfIcon}
                                      style={{ width: "32px", height: "38px" }}
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontWeight: 500,
                                          fontSize:
                                            windowWidth > 1024
                                              ? "14px"
                                              : "12px",
                                          fontFamily: "Segoe UI",
                                          color: "#000000",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Preview File
                                      </p>
                                      {/* <p style={{ fontWeight: 500, fontSize: windowWidth > 1024 ? "14px" : "12px", fontFamily: "Segoe UI", color: "rgba(0, 0, 0, 0.6)" }} >{numPages} pages - PDF - {templateData?.header?.example?.header_handle[0].size}</p> */}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <img
                            src={
                              headerData?.fileType == "image"
                                ? dummyImgForHeader
                                : headerData?.fileType == "video"
                                ? dummyVideoImgForHeader
                                : headerData?.fileType == "document"
                                ? dummyDocumentForHeader
                                : headerData?.fileType == "location"
                                ? dummyLocatImgForHeader
                                : null
                            }
                            style={{
                              width: "100%",
                              // height: "150px",
                              maxHeight: "400px",

                              objectFit: "contain",
                            }}
                          />
                        )}
                      </Box>
                    ) : null}
                    <div
                      className="preview-screen"
                      style={{ padding: "13px", paddingTop: "0px" }}
                    >
                      {formData.header !== "" && (
                        <div className="heading" style={{ paddingTop: "12px" }}>
                          <h4
                            style={{
                              fontFamily: "SEGOE UI",
                              wordWrap: "break-word",
                            }}
                            className="text-[16px] font-semibold leading-[22px] text-[#000000] mt-[12px]"
                          >
                            {formData.header}
                          </h4>
                        </div>
                      )}
                      {formData.body !== "" && (
                        <div
                          className="content"
                          style={{
                            marginBottom: "0px",
                            paddingTop: formData.header == "" ? "15px" : "",
                          }}
                        >
                          <h4
                            style={{
                              fontFamily: "SEGOE UI",
                              whiteSpace: "pre-line",
                              textWrap: "wrap",
                              wordWrap: "break-word",
                            }}
                            className="text-[14px] font-normal leading-[22px] text-[#000000] "
                          >
                            {formData.body}
                          </h4>
                        </div>
                      )}
                      {formData.footer !== "" && (
                        <div
                          className="content"
                          style={{ marginBottom: "0px", marginTop: "12px" }}
                        >
                          <h4
                            style={{
                              fontFamily: "SEGOE UI",
                              wordWrap: "break-word",
                            }}
                            className="text-[14px] font-medium leading-[22px] text-[#000000]"
                          >
                            {formData.footer}
                          </h4>
                        </div>
                      )}
                    </div>
                    {buttonType == "calltoaction" && (
                      <div className="px-0 ">
                        {callToActionData[0].action === "PHONE_NUMBER" && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              paddingBottom: "20px",
                            }}
                          >
                            {callToActionData[0].text !== "" && (
                              <a
                                href="#"
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                }}
                              >
                                <img src={Reciver} alt="icon" />
                                {callToActionData[0].text}
                              </a>
                            )}
                            {numcallToActionButtons > 1 &&
                              callToActionData[1].text !== "" && (
                                <a
                                  href="#"
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                  }}
                                >
                                  <img src={Linkforward} alt="icon" />
                                  {callToActionData[1].text}
                                </a>
                              )}
                          </div>
                        )}
                        {callToActionData[0].action != "PHONE_NUMBER" && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              paddingBottom: "20px",
                            }}
                          >
                            {callToActionData[0].text !== "" && (
                              <a
                                href="#"
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                }}
                              >
                                <img src={Linkforward} alt="icon" />
                                {callToActionData[0].text}
                              </a>
                            )}
                            {numcallToActionButtons > 1 &&
                              callToActionData[1] &&
                              callToActionData[1].text !== "" && (
                                <a
                                  href="#"
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                  }}
                                >
                                  <img src={Reciver} alt="icon" />
                                  {callToActionData[1].text}
                                </a>
                              )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {buttonType == "quickreply" && (
                    <Row className="flex justify-center items-center mt-[5px]">
                      {buttonType == "quickreply" &&
                        quickReplyData[0].data !== "" && (
                          <Col
                            xs={6}
                            className=""
                            style={{ paddingRight: "2px" }}
                          >
                            <Box className="bg-[#FFFFFF] flex justify-center items-center p-[12px] rounded-md w-100">
                              <h4
                                style={{ fontFamily: "SEGOE UI" }}
                                className="text-[14px]  font-medium leading-[18px] text-[#0C7FD1]"
                              >
                                {quickReplyData[0].data}
                              </h4>
                            </Box>
                          </Col>
                        )}

                      {numQuickReplyButtons > 1 &&
                        quickReplyData[1].data !== "" && (
                          <Col
                            xs={6}
                            style={{ paddingLeft: "2px" }}
                            className=" w-50"
                          >
                            <Box className="bg-[#FFFFFF] flex justify-center items-center p-[12px] rounded-md w-100">
                              <h4
                                style={{ fontFamily: "SEGOE UI" }}
                                className="text-[14px]  font-medium leading-[18px] text-[#0C7FD1]"
                              >
                                {quickReplyData[1].data}
                              </h4>
                            </Box>
                          </Col>
                        )}

                      {numQuickReplyButtons > 2 &&
                        quickReplyData[2].data !== "" && (
                          <div className="event-time">
                            <Col xs={12} className="pe-0">
                              <Box className="bg-[#FFFFFF] flex justify-center items-center p-[12px] rounded-md w-100">
                                <h4
                                  style={{ fontFamily: "SEGOE UI" }}
                                  className="text-[14px]  font-medium leading-[18px] text-[#0C7FD1]"
                                >
                                  {quickReplyData[2].data}
                                </h4>
                              </Box>
                            </Col>
                          </div>
                        )}
                    </Row>
                  )}
                </div>
              )}

              <Row className="pt-3 mb-6">
                <Col sm={12} className="text-end">
                  {isEdit ? (
                    <Box className="flex justify-start items-start mt-[18px] w-100 d-block d-md-none ">
                      <MuiButton
                        onClick={updateRequest}
                        disabled={sending}
                        variant="contained"
                        disableRipple
                        sx={{
                          background: "#165E5B",
                          color: "#FFFFFF",
                          borderRadius: "5px",
                          width: "100%",
                          height: "45px",
                          textTransform: "none",
                          boxShadow: "none",
                          fontWeight: 600,
                          fontSize: "16px",
                          fontFamily: "Segoe UI",
                          "&:hover": { backgroundColor: "#165E5B" },
                        }}
                      >
                        {sending ? <Spinner size="sm" /> : "Update Template"}
                      </MuiButton>
                    </Box>
                  ) : (
                    <Box className="flex justify-start items-start mt-[18px] w-100 d-block d-md-none ">
                      <MuiButton
                        onClick={createRequest}
                        disabled={sending}
                        variant="contained"
                        disableRipple
                        sx={{
                          background: "#165E5B",
                          color: "#FFFFFF",
                          borderRadius: "5px",
                          width: "100%",
                          height: "45px",
                          textTransform: "none",
                          boxShadow: "none",
                          fontWeight: 600,
                          fontSize: "16px",
                          fontFamily: "Segoe UI",
                          "&:hover": { backgroundColor: "#165E5B" },
                        }}
                      >
                        {sending ? <Spinner size="sm" /> : "Create Template"}
                      </MuiButton>
                    </Box>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Box>

      <Modal
        size="md"
        className=" delete-modal"
        show={isShowMapModal}
        onHide={() => setIsShowMapModal(false)}
      >
        <Modal.Header
          closeButton
          style={{
            padding: "15px 18px",
            background: "#F3F7F7",
            borderBottom: "none",
          }}
        >
          <Modal.Title
            style={{
              fontWeight: 700,
              fontSize: "16px",
              fontFamily: "Segoe UI",
              color: "#000000",
            }}
          >
            Set Location
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ padding: "0px", background: "#F3F7F7", borderRadius: "5px" }}
        >
          <StandaloneSearchBox
            onLoad={onLoadSearchField}
            onPlacesChanged={onPlacesChanged}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginBottom: "14px",
              }}
            >
              <input
                ref={inputRef}
                type="text"
                placeholder="Search"
                style={{
                  boxSizing: `border-box`,
                  border: `1px solid #D9D9D9`,
                  width: `94%`,
                  height: `38px`,
                  padding: `8px 13px`,
                  borderRadius: `5px`,
                  fontSize: `14px`,
                  outline: `none`,
                  textOverflow: `ellipses`,
                  zIndex: 99999999,
                }}
              />
            </div>
          </StandaloneSearchBox>
          <GoogleMap
            center={markerPosition}
            defaultCenter={markerPos}
            zoom={20}
            mapContainerStyle={containerStyle}
            onClick={handelClickOnMap}
            options={options}
          >
            <Marker position={markerPosition} />
          </GoogleMap>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginTop: "13px",
              marginBottom: "16px",
            }}
          >
            <MuiButton
              onClick={handleSetLocation}
              variant="contained"
              disableRipple
              sx={{
                background: "#165E5B",
                color: "#FFFFFF",
                borderRadius: "5px",
                width: "94%",
                height: "39px",
                textTransform: "none",
                boxShadow: "none",
                fontWeight: 600,
                fontSize: "16px",
                fontFamily: "Segoe UI",
                "&:hover": { backgroundColor: "#165E5B" },
              }}
            >
              Set Location
            </MuiButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Createtemplate;
