import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

import Select, { components } from "react-select";

import Template_ic from "../../images/icons/template-ic.svg";
import dropdownIcon from "../../images/icons/select-field-icon.svg";

import "./css/templateListing-header.css";

const TemplateListingHeader = (props) => {
  const options = [
    { value: "all", label: "All" },
    { value: "marketing", label: "Marketing" },
    { value: "utility", label: "Utility" },
    { value: "authentication", label: "Authentication" },
  ];

  const status = [
    { value: "all", label: "All" },
    { value: "approved", label: "Approved" },
    { value: "pending", label: "Pending" },
    { value: "rejected", label: "Rejected" },
  ];

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={dropdownIcon} />
      </components.DropdownIndicator>
    );
  };

  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "#000000",
      fontWeight: 500,
      fontSize: "14px",
      cursor: "pointer",
      "&:hover": { backgroundColor: "#E2FFF0", color: "#000000" },
    }),
  };

  return (
    <>
      <section className="template-header py-3" style={{ width: "100%" }}>
        <Container>
          <Row className="align-items-center">
            <Col xs={6} lg={3}>
              <div className="template-heading">
                <img
                  src={Template_ic}
                  alt="icon"
                  style={{ marginRight: "5px" }}
                />{" "}
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Segoe UI",
                  }}
                >
                  Templates
                </p>{" "}
                <span className="badge d-none">
                  {props?.templatesOrg?.length}
                </span>
              </div>
            </Col>
            <Col md={6} className="d-none d-lg-block">
              <Row>
                <Col md={6} className="px-md-1">
                  <Form.Group className="form-group">
                    <Form.Control
                      placeholder="Search"
                      onChange={(e) =>
                        props?.getSearchedTemplates(e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={3} className="px-md-1">
                  <Form.Group>
                    <Select
                      className="c-select create-template create-template-header"
                      onChange={(val) =>
                        props?.getCategorizedTemplates(val?.value)
                      }
                      options={options}
                      placeholder={"Category"}
                      classNamePrefix="select"
                    />
                  </Form.Group>
                </Col>
                <Col md={3} className="px-md-1">
                  <Form.Group>
                    <Select
                      className="c-select create-template create-template-header"
                      onChange={(val) =>
                        props?.getStatusWiseTemplates(val?.value)
                      }
                      options={status}
                      placeholder={"Status"}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col xs={6} lg={3} className="text-end">
              <Link
                to="/createTemplate"
                className="btn btn-main text-decoration-none btn-main-mob"
              >
                <i className="bi bi-plus"></i> <span>Create Template</span>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="template-header-mob d-lg-none py-3">
        <Container>
          <Row>
            <Col xs={12} className="mb-3">
              <Form.Group className="form-group">
                <Form.Control placeholder="Search" />
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={6} className="pe-1">
              <Form.Group>
                <Select
                  className="c-select"
                  onChange={(val) => props?.getCategorizedTemplates(val?.value)}
                  options={options}
                  placeholder={"Category"}
                  classNamePrefix="select"
                />
              </Form.Group>
            </Col>
            <Col xs={6} className="ps-1">
              <Form.Group>
                <Select
                  className="c-select"
                  // options={status}
                  // placeholder={"Status"}
                  closeMenuOnSelect={false}
                  // className="c-select create-template create-template-header"
                  onChange={(val) => props?.getStatusWiseTemplates(val?.value)}
                  options={status}
                  placeholder={"Status"}
                />
              </Form.Group>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default TemplateListingHeader;
