import React, { useEffect, useState, useLayoutEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";

import { onMessage } from "firebase/messaging";
import { messaging } from "./firebaseConfig";
import "./App.css";
import { store } from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/main-style.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client";
import Muzamil_img from "./images/muzumil.png";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import BellIcon from "./images/icons/Bell-icon.svg";

import Router from "./Router";
import { getInbox } from "./services/Inbox";
import getconfig from "./config";
import {
  removeAllConversationsData,
  removeAllChatsData,
  addNewMessageToChat,
  addNewChatToChatList,
  setConversationsData,
} from "./reduxSlice/chats";
import {
  setBroadcastUpdate,
  setMessageFailed,
  setMessageStatus,
  setNewMessage,
  updateUnreadChats,
  setNotificationDetail,
  setUnreadCount,
} from "./reduxSlice/messageNotificationSlice";
import notificationSound from "./sound/notification-sound.mp3";
import { removeAllContactsData } from "./reduxSlice/contacts";
import { removeAllGroupsData } from "./reduxSlice/groups";
import { removeAllBroadCastsData } from "./reduxSlice/broadcast";
import { removeAllTemplatesData } from "./reduxSlice/templates";
import { removeAllCompUsersData } from "./reduxSlice/users";
import { removeAllTagsData } from "./reduxSlice/tags";
import {
  getAllNotifications,
  logOutUser,
  registerUserForNotifications,
  unreadNotificationCount,
} from "./services/User";
import { requestPermission } from "./firebaseConfig";
import { async } from "@firebase/util";

function App() {
  const [socket, setSocket] = useState(null);
  const [fcmToken, setFcmToken] = useState("");
  const [notificationData, setNotificationData] = useState(null);
  const pathname = useLocation();
  const dispatch = useDispatch();
  const companyId = localStorage.getItem("companyId");
  // const userId = localStorage.getItem("userId");
  const { notificationDetail } = useSelector(
    (state) => state.MessageNotification
  );

  // useEffect(() => {
  //   const registerServiceWorker = async () => {
  //     if ("serviceWorker" in navigator) {
  //       const registrations = await navigator.serviceWorker.getRegistrations();
  //       console.log("Service Worker Registrations:", registrations);

  //       // Check if a service worker is already registered
  //       if (registrations.length === 0) {
  //         navigator.serviceWorker
  //           .register("/firebase-messaging-sw.js")
  //           .then((registration) => {
  //             console.log(
  //               "Service Worker registered with scope:",
  //               registration.scope
  //             );
  //           })
  //           .catch((error) => {
  //             console.error("Service Worker registration failed:", error);
  //           });
  //       } else {
  //         console.log("Service Worker already registered");
  //       }
  //     }
  //   };

  //   registerServiceWorker();
  // }, []);

  useEffect(() => {
    let offlineToastId;
    let shouldShowSuccessToast = false;

    const handleOnlineStatus = () => {
      if (!navigator.onLine) {
        // Show error toast without autoClose if offline
        offlineToastId = toast.error("No internet connection!", {
          autoClose: false, // Stay on screen until dismissed
        });
        shouldShowSuccessToast = true;
      } else if (shouldShowSuccessToast) {
        // If back online, remove the offline toast if it exists
        if (offlineToastId) {
          toast.dismiss(offlineToastId);
          offlineToastId = null;
        }
        // Show a success message briefly
        toast.success("You're back online!", { autoClose: 2000 });
      }
    };

    // Listen for online and offline events
    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    // Initial check in case the component mounts while offline
    handleOnlineStatus();

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
      if (offlineToastId) toast.dismiss(offlineToastId); // Ensure toast is removed on cleanup
    };
  }, []);

  useEffect(() => {
    const fetchFcmToken = async () => {
      try {
        const token = await requestPermission();
        console.log("Token is ", token);
        setFcmToken(token);
        // if (Notification.permission === "granted") {
        // If notifications are enabled, call the API
        await registerUserForNotification(token);
        // } else if (Notification.permission === "denied") {
        //   await unRegisterUserForNotification();
        //   // If notifications are blocked, handle accordingly
        // }
      } catch (error) {
        console.log("Token error", error);
      }
    };
    fetchFcmToken();
  }, []);
  useEffect(() => {}, []);

  const registerUserForNotification = async (fcmToken) => {
    const token = localStorage.getItem("token");
    // const fcmToken = localStorage.getItem("fcmToken");

    // Use fetch instead of axios for the notification registration request
    const response = await fetch(
      "https://utter-api.mwancloud.com/notification/register",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Replace axios header with fetch equivalent
        },
        body: JSON.stringify({ token: fcmToken }),
      }
    );
    localStorage.setItem("fcmToken", fcmToken);
  };

  const unRegisterUserForNotification = async () => {
    const token = localStorage.getItem("token");

    const response = await fetch(
      "https://utter-api.mwancloud.com/notification/unregister",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Replace axios header with fetch equivalent
        },
      }
    );
    localStorage.removeItem("fcmToken");
  };

  const checkNotificationPermissions = async () => {};
  // useEffect(() => {
  //   // Listen for foreground messages
  //   onMessage(messaging, (payload) => {
  //     console.log("Message received in the foreground:", payload);
  //     // Handle display of notification, or trigger UI updates if necessary
  //     // alert(`New message: ${payload.notification.title}`);
  //     const response = await getAllNotifications();
  //     console.log("allNotifications", response);
  //     setNotificationData(response?.data);
  //     const audio = new Audio(notificationSound);
  //     audio
  //       .play()
  //       .catch((error) =>
  //         console.error("Error playing notification sound:", error)
  //       );
  //     dispatch(setNotificationDetail(payload));
  //   });
  // }, []);
  // useEffect(() => {
  //   if ("serviceWorker" in navigator) {
  //     navigator.serviceWorker
  //       .register("/firebase-messaging-sw.js") // Adjust the path as necessary
  //       .then((registration) => {
  //         console.log(
  //           "Service Worker registered with scope:",
  //           registration.scope
  //         );
  //       })
  //       .catch((error) => {
  //         console.error("Service Worker registration failed:", error);
  //       });
  //   }
  // }, []);

  useEffect(() => {
    // const video = document.createElement("video");
    // video.src = "/sound/video.mp4"; // A silent video file
    // video.muted = true;
    // video.style.display = "none";
    // document.body.appendChild(video);

    // // Play the video once to allow autoplay sounds
    // video
    //   .play()
    //   .then(() => {
    //     console.log("Autoplay enabled for future sounds");
    //   })
    //   .catch((error) => {
    //     console.error("Failed to autoplay video", error);
    //   });
    // Define an async function to handle the foreground message
    const handleForegroundMessage = async (payload) => {
      console.log("Message received in the foreground:", payload);

      try {
        // Await the async call to get notifications
        const response = await getAllNotifications();
        console.log("allNotifications", response);
        setNotificationData(response?.data);
        dispatch(setNotificationDetail(response?.data));
        const unReadNotification = await unreadNotificationCount();
        console.log("unReadNotification", unReadNotification);
        dispatch(setUnreadCount(unReadNotification?.unreadMessage));

        // Play notification sound
        // const audio = new Audio(notificationSound);
        // // setTimeout(async () => {
        // await audio.play().catch((error) => {
        //   console.error("Error playing notification sound:", error);
        // });
        // }, 1000);

        // Dispatch the notification payload
      } catch (error) {
        console.error("Error handling foreground message:", error);
      }
    };

    // Listen for foreground messages
    const unsubscribe = onMessage(messaging, (payload) => {
      // if (payload.data) {
      // Handle the notification based on the `data` payload
      handleForegroundMessage(payload);
      // }
    });

    // Clean up the listener when the component unmounts
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [dispatch]);

  useEffect(() => {
    const fetchUnReadNotifications = async () => {
      const unReadNotification = await unreadNotificationCount();
      console.log("unReadNotification", unReadNotification);
      dispatch(setUnreadCount(unReadNotification?.unreadMessage));
    };
    fetchUnReadNotifications();
  }, []);

  // useEffect(() => {
  //   if (socket == null)
  //     setSocket(io(getconfig.backend, { autoConnect: true }).connect());
  // }, []);
  const setSokectConnection = () => {
    if (socket == null) {
      const companyId = localStorage.getItem("companyId");
      if (!companyId) return;
      const sock = io(getconfig.backend, { autoConnect: true }).connect();
      sock.emit("userConnect", companyId);
      setSocket(sock);
    }
  };

  useEffect(() => {
    getAllUnReadChatsCount();
  }, []);

  document.addEventListener("userLoggedIn", (data) => getAllUnReadChatsCount());

  // get user unread messages count
  const getAllUnReadChatsCount = async () => {
    const companyId = localStorage.getItem("companyId");
    const { data } = await axios.get(
      `${getconfig.backend}user/getNewConversationCount/${companyId}`
    );
    let temp = [];
    if (data?.success) {
      if (data?.data?.length > 0) {
        const allData = store.getState();
        const { allConversations } = allData?.ChatsReducer;

        // sort conversations
        let givenData = [...allConversations];
        let sortedData = givenData.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        dispatch(setConversationsData(sortedData));
      }

      for (let i = 0; i != data?.data?.length; i++) {
        temp.push({
          contact: data?.data[i]?.contact?._id,
        });
      }
      console.log("going to push : ", temp);
      dispatch(updateUnreadChats({ users: temp, contact: null }));
    }

    console.log("data of unread chats count", data);
  };

  // update user lists on receiving new message
  // const updateUserLists = (chatId, newMsg, updatedInbox) => {
  //   let allConvers = allConversations;
  //   const selectedConversation = allConvers.findIndex(
  //     (item) => item?._id == chatId
  //   );
  //   if (selectedConversation) {
  //     const newMsgReceivedEvent = new Event("newMessageReceivedEvent", {
  //       detail: { chatId: chatId, msg: newMsg },
  //     });
  //     document.dispatchEvent(newMsgReceivedEvent);
  //   } else {
  //     // if not found, adding chat to chat list in redux store
  //     dispatch(addNewChatToChatList(updatedInbox));
  //   }
  // };
  // get latest conversations on reload any page
  const getLatestConversations = async () => {
    const response = await getInbox();
    if (response?.success) {
      let givenData = response?.data;
      let sortedData = givenData.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      dispatch(setConversationsData(sortedData));
      console.log("sortedData", sortedData);
    }
  };

  // get all conversations on reload
  // useEffect(() => {
  //   getLatestConversations();
  // }, []);

  useEffect(() => {
    if (!socket) return;
    function onConnect() {
      socket.emit("userConnect", companyId);
      console.log("connect");
    }

    function onDisconnect() {
      socket.emit("userDisconnect", companyId);
      console.log("Disconnect");
    }

    async function EventListenerFunc(event) {
      const userId = localStorage.getItem("userId");

      const action = event.action;
      const data = event.data;
      console.log("action ::::", action);
      // Helper function to fetch unread notifications and play sound
      const handleNewNotification = async () => {
        const unReadNotification = await unreadNotificationCount();
        console.log("unReadNotification", unReadNotification);
        dispatch(setUnreadCount(unReadNotification?.unreadMessage));
        const isSoundMuted = localStorage.getItem("muteSound") === "true";
        // Play notification sound
        if (!isSoundMuted) {
          const audio = new Audio(notificationSound);
          await audio.play().catch((error) => {
            console.error("Error playing notification sound:", error);
          });
        }
      };
      switch (action) {
        case "newPushNotification":
          console.log("newPushNotification data received : ", data);
          const isUserIdMatched = data?.filter((d) => d == userId);

          if (isUserIdMatched && isUserIdMatched?.length > 0) {
            await handleNewNotification();
          }
          // }
          break;
        case "Logout":
          if (data?.userId == userId) {
            const token = localStorage.getItem("token");

            socket?.emit("userDisconnect", companyId);
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            localStorage.removeItem("companyId");
            setSocket(null);
            window.location.href = "/login";
          }
          break;
        case "newMessage":
          console.log("newMessage data received : ", data);
          if (companyId == data?.newMsgData?.company) {
            const newMsgReceivedEvent = new CustomEvent(
              "newMessageReceivedEvent",
              {
                detail: {
                  chatId: data?.newMsgData?._id,
                  msg: data?.newMsgData,
                  updatedInbox: data?.userChatData,
                  assignedTag: data?.assignedTag?._id,
                },
              }
            );
            document.dispatchEvent(newMsgReceivedEvent);
          }
          break;

        case "newMessageSent":
          console.log("newMessageSent data received : ", data);
          if (
            companyId == data?.companyId
            // &&
            // data?.newMessage?.from?._id != userId
          ) {
            const newMsgSentEvent = new CustomEvent("newMsgSentEvent", {
              detail: {
                chatId: data?.chatId,
                msg: data?.newMessage,
              },
            });
            document.dispatchEvent(newMsgSentEvent);
          }

          break;
        case "agentChanged":
          console.log("agentChanged data received : ", data, ":", userId);
          // if (companyId == data?.companyId && data?.senderId != userId) {
          if (companyId == data?.companyId) {
            const chatAgentChangedEvent = new CustomEvent(
              "chatAgentChangedEvent",
              {
                detail: {
                  chatId: data?.inboxId,
                  newAgents: data?.userIds,
                },
              }
            );
            document.dispatchEvent(chatAgentChangedEvent);
          }

          break;
        case "tagChanged":
          console.log("tagChanged data received : ", data);
          if (companyId == data?.companyId) {
            const chatTagChangedEvent = new CustomEvent("chatTagChangedEvent", {
              detail: {
                chatId: data?.inboxId,
                tagId: data?.tagId,
              },
            });
            document.dispatchEvent(chatTagChangedEvent);
          }

          break;
        case "noteUpdate":
          console.log(
            "noteUpdate data received : ",
            data,
            userId,
            ":",
            companyId == data?.companyId && data?.senderId != userId
          );
          if (companyId == data?.companyId && data?.senderId != userId) {
            console.log("going to send ");
            const notesEvent = new CustomEvent("notesEvent", {
              detail: {
                chatId: data?.inboxId,
              },
            });
            document.dispatchEvent(notesEvent);
          }

          break;
        case "newChatAdded":
          console.log("newChatAdded data received : ", data);
          if (companyId == data?.companyId) {
            const newChatAdded = new CustomEvent("newChatAdded", {
              detail: {
                chatId: data?.chatId,
                newChat: data?.newChat,
              },
            });
            document.dispatchEvent(newChatAdded);
          }

          break;
        case "chatDeleted":
          console.log("chatDeleted data received : ", data);
          if (companyId == data?.companyId) {
            const chatDeleted = new CustomEvent("chatDeleted", {
              detail: {
                chatId: data?.chatId,
              },
            });
            document.dispatchEvent(chatDeleted);
          }

          break;
        case "chatMuted":
          console.log("chatMuted data received : ", data, ":", userId);
          if (companyId == data?.companyId && data?.creatorId != userId) {
            const chatMuteChangedEvent = new CustomEvent(
              "chatMuteChangedEvent",
              {
                detail: {
                  chatId: data?.chatId,
                },
              }
            );
            document.dispatchEvent(chatMuteChangedEvent);
          }

          break;
        case "chatBlock":
          console.log("chatBlock data received : ", data, ":", userId);
          if (companyId == data?.companyId && data?.creatorId != userId) {
            const chatBlockChangedEvent = new CustomEvent(
              "chatBlockChangedEvent",
              {
                detail: {
                  chatId: data?.chatId,
                },
              }
            );
            document.dispatchEvent(chatBlockChangedEvent);
          }

          break;
        case "status":
          console.log("status changed ====", data, data.messageId);
          const msgReadStatusChanged = new CustomEvent("msgReadStatusChanged", {
            detail: {
              msgData: data,
            },
          });
          document.dispatchEvent(msgReadStatusChanged);

          //dispatch(setMessageStatus(data));
          break;
        case "failed":
          console.log("status changed failed ====");
          const msgFailedStatusChanged = new CustomEvent(
            "msgFailedStatusChanged",
            {
              detail: {
                msgData: data,
              },
            }
          );
          document.dispatchEvent(msgFailedStatusChanged);
          // dispatch(setMessageFailed(data));
          break;
        case "broadcastUpdate":
          dispatch(setBroadcastUpdate(true));
          break;
        case "msgEdited":
          console.log("msgEdited data received : ", data);
          if (companyId == data?.companyId && data?.senderId != userId) {
            const msgEditedEvent = new CustomEvent("msgEditedEvent", {
              detail: {
                chatId: data?.chatId,
                updatedMsg: data?.updatedMsg,
                text: data?.updatedMsg?.text,
              },
            });
            document.dispatchEvent(msgEditedEvent);
          }
          break;
        case "msgDeleted":
          console.log("msgDeleted data received : ", data);
          if (companyId == data?.companyId && data?.senderId != userId) {
            const msgDeletedEvent = new CustomEvent("msgDeletedEvent", {
              detail: { chatId: data?.chatId, msgId: data?.msgId },
            });
            document.dispatchEvent(msgDeletedEvent);
          }
          break;
        default:
          console.log("No Socket Action Matched  ");
          break;
      }
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("message", EventListenerFunc);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("message", EventListenerFunc);
    };
  }, [socket]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (socket && socket.connected) {
        socket.emit("ping"); // Send a ping message to keep the connection alive
      } else {
        console.log("socket disconnected");
      }
    }, 30000); // Ping every 30 seconds

    return () => clearInterval(interval); // Clean up on unmount
  }, [socket]);

  useEffect(() => {
    const onBeforeUnload = (event) => {
      console.log("SOME CODE HERE");
      dispatch(removeAllContactsData());
      dispatch(removeAllGroupsData());
      dispatch(removeAllBroadCastsData());
      dispatch(removeAllTemplatesData());
      dispatch(removeAllCompUsersData());
      dispatch(removeAllTagsData());
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  return (
    <>
      <Router
        socket={socket}
        setSokectConnection={setSokectConnection}
        setSocket={setSocket}
      />
      <ToastContainer className={"toast-position"} />
    </>
  );
}

export default App;
