import React, { useState, useRef, useEffect, useMemo } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import Grouptable from "../components/group-table/g-table";
import Search from "../components/search/search";
import Modaltable from "../components/modal-table/mTable";
import Companyactionbtn from "../components/company-listing/compnay-actionbtn";
import Actionbutton from "../components/tableAction/tableactionBtn1";
import {
  getAllGroups,
  createGroup,
  deleteGroup,
  editGroup1,
  searchGroup,
} from "../services/Group/index";
import { getAllContacts } from "../services/Contacts";
import moment from "moment";
import { Table } from "antd";
import "./../components/group-table/group-contact.css";
import Allcontacts from "../components/group-table/all-contacts";
import { toast } from "react-toastify";
import WindowWidthDetector from "../components/customHooks/DeviceWidthDetector";
import TableSkelton from "../components/skeletons/TableLoader";
import { setGroupsData } from "../reduxSlice/groups";
import { Box, Pagination } from "@mui/material";
import { Spinner } from "react-bootstrap";

const Contact = () => {
  const { allGroups, totalRecords } = useSelector(
    (state) => state.GroupsReducer
  );
  const dispatch = useDispatch();
  console.log("allGroups", allGroups);
  const [paginationData, setPaginationData] = useState({
    totalPages: 1,
    currPage: 1,
  });
  console.log("totalRecords", totalRecords);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const width = WindowWidthDetector();
  const [cgshow, setCgShow] = useState(false);
  const [cgtshow, setCgtShow] = useState(false);
  const [cvsshow, setCvsShow] = useState(false);
  const [newgroupshow, setNewgroupShow] = useState(false);
  const [groups, setGroups] = useState([]);
  const [groupsOrgData, setGroupsOrgData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const inputRef = useRef(null);
  const [selectedGroupId, setSelectedGrpId] = useState(null);
  const [allContacts, setAllContacts] = useState([]);
  const [isContactsFetching, setIsContactsFetching] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showError1, setShowError1] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [updateScreen, setUpdateScreen] = useState(false);
  const [groupContacts, setGroupContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState(""); // Filter by search text
  const [isCreating, setIsCreating] = useState(false);
  const handleClick = () => {
    inputRef.current.click();
  };

  const handleInputChange = (e) => {
    setGroupName(e.target.value);
    if (e.target.value.length > 0) {
      setShowError1(false);
    }
  };

  const handleSelected = (contacts) => {
    setSelectedContacts(contacts);
    if (contacts.length > 0) {
      setShowError(false);
    }
  };

  const handleGroupContacts = (contacts) => {
    console.log("contacts in func", contacts);
    setGroupContacts(contacts);
  };

  const fetchContacts = async () => {
    setIsContactsFetching(true);
    try {
      const resp = await getAllContacts(1, 100000);
      console.log("resp of contacts : ", resp);
      if (resp.success) {
        setAllContacts(resp.data);
        setIsContactsFetching(false);

        //fetchData(resp.data);
      } else throw new Error(resp.error);
    } catch (error) {
      console.log(error);
    }
  };

  const addGroup = () => {
    setCgtShow(true);
    if (allContacts?.length < 1) {
      fetchContacts();
    }
  };

  // useEffect(() => {
  //     fetchData()
  // }, [updateScreen])

  useEffect(() => {
    if (!cgtshow && !newgroupshow) {
      setSelectedContacts([]);
      setGroupName("");
      setShowError(false);
      setSearchQuery1("");
    }
  }, [newgroupshow, cgtshow]);

  // setting data of groups
  const setAllGroupsData = (groupsData, records = totalRecords) => {
    console.log("data of groups got :", groupsData);
    setGroups(handleDataForTable(groupsData));
    setGroupsOrgData(handleDataForTable(groupsData));

    dispatch(setGroupsData({ data: groupsData, totalRecords: records }));
  };

  // const fetchData = async (page = 1, pageSize = 10) => {
  //   try {
  //     if (pageSize) {
  //       setIsFetchingMore(true);
  //     } else {
  //       setLoading(true);
  //     }

  //     let groupsData = [],
  //       newTotalRecords = 0;
  //     let finalPageSize = pageSize ? pageSize : paginationData?.currPage;
  //     let totalPages =
  //       paginationData?.totalPages > 0 ? paginationData?.totalPages : 10000000;
  //     let diff = finalPageSize > 1 ? finalPageSize * 10 - totalPages : 0;
  //     finalPageSize =
  //       finalPageSize * 10 > totalPages
  //         ? finalPageSize * 10 - diff
  //         : finalPageSize * 10;
  //     console.log(
  //       "pageSize in groups",
  //       ":",
  //       pageSize,
  //       ":",
  //       diff,
  //       ":",
  //       finalPageSize,
  //       ":",
  //       totalPages,
  //       ":",
  //       allGroups?.length
  //     );

  //     if (
  //       allGroups?.length >= 10
  //         ? allGroups?.length >= finalPageSize
  //         : allGroups?.length > 0
  //     ) {
  //       console.log("inside of if");
  //       groupsData = Object.values(allGroups);
  //       newTotalRecords = totalRecords;
  //       setPaginationData((prev) => ({ ...prev, pageSize: prev.pageSize + 1 }));
  //     } else {
  //       const resp = await getAllGroups(
  //         finalPageSize > 0 ? finalPageSize / 10 : 1
  //       );
  //       if (resp.success) {
  //         groupsData = [...resp.data, ...allGroups];
  //         console.log("data of all groups : ", resp, ":", resp?.totalPages);
  //         newTotalRecords = resp?.totalRecords;
  //         setPaginationData((prev) => ({
  //           pageSize: prev.pageSize + 1,
  //           totalPages: resp?.totalRecords,
  //         }));
  //         // setGroupsOrgData(
  //         //   handleDataForTable(resp.data, contacts || allContacts)
  //         // );
  //         // setGroups(handleDataForTable(resp.data, contacts || allContacts));
  //       } else throw new Error(resp.error);
  //     }

  //     setLoading(false);
  //     setAllGroupsData(groupsData, newTotalRecords);
  //     setIsFetchingMore(false);
  //   } catch (error) {
  //     console.log("Failed to fetch Groups!");
  //   }
  // };

  const fetchData = async (page = 1, pageSize = 20, search = "") => {
    setLoading(true);
    try {
      let groupsData = [];
      const resp = await searchGroup(page, pageSize, search);

      if (resp.success) {
        const { data, totalRecords } = resp;

        groupsData = [...data]; // Append new data

        setAllGroupsData(groupsData, totalRecords);
        setPaginationData({
          currPage: page,
          totalPages: Math.ceil(totalRecords / pageSize),
        });
        setCurrentPage(page);
      } else {
        throw new Error(resp.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  // const createNewGroup = async () => {
  //   const groupData = {
  //     groupName: groupName,
  //     contacts: selectedContacts,
  //   };
  //   console.log(groupData);

  //   try {
  //     console.log(groupData);
  //     const resp = await createGroup(groupData);
  //     if (resp.success) {
  //       console.log("Group Created successfully");
  //       setUpdateScreen((prevUpdateScreen) => !prevUpdateScreen);
  //       //fetchData(allContacts);
  //       setAllGroupsData([resp?.data, ...allGroups]);
  //     } else throw new Error(resp.error);
  //   } catch (error) {
  //     toast.error(error.message);
  //     console.log(error.message);
  //   }
  // };

  const createNewGroup = async () => {
    const groupData = {
      groupName: groupName,
      contacts: selectedContacts,
    };
    console.log(groupData);
    setIsCreating(true);
    try {
      const resp = await createGroup(groupData);
      if (resp.success) {
        console.log("Group Created successfully");

        // Update the groups data
        const updatedGroups = [resp.data, ...allGroups];
        // setAllGroupsData(updatedGroups);

        // Update total records count and pagination
        const newTotalRecords = updatedGroups.length; // Assuming all groups are loaded
        const newTotalPages = Math.ceil(newTotalRecords / 10); // Assuming 10 items per page

        // setPaginationData({
        //   currPage:
        //     newTotalRecords > paginationData.currPage * 10
        //       ? paginationData.currPage + 1
        //       : paginationData.currPage,
        //   totalPages: newTotalPages,
        //   totalRecords: newTotalRecords,
        // });
        fetchData(
          paginationData.currPage > paginationData.totalPages &&
            paginationData.totalPages > 0
            ? paginationData.currPage - 1
            : paginationData.currPage,
          20,
          searchText
        );

        // fetchData(
        //   newTotalRecords > paginationData.currPage * 10
        //     ? paginationData.currPage + 1
        //     : paginationData.currPage,
        //   10
        // );

        // Trigger re-render or other state updates if necessary
        setUpdateScreen((prevUpdateScreen) => !prevUpdateScreen);
        setNewgroupShow(false);
      } else {
        throw new Error(resp.error);
      }
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    } finally {
      setIsCreating(false);
    }
  };

  // const handleDelete = async (id) => {
  //   console.log(id);
  //   const isDeleted = await deleteGroup(id);
  //   if (isDeleted?.success) {
  //     setUpdateScreen((prevUpdateScreen) => !prevUpdateScreen);
  //     //fetchData(allContacts);

  //     let updatedRecords = [...allGroups];
  //     let filteredRecords = updatedRecords.filter((item) => item?._id != id);
  //     setAllGroupsData([...filteredRecords]);
  //     console.log("Group Deleted Successfully!");
  //   } else {
  //     console.log("Something went wrong");
  //   }
  // };

  const handleDelete = async (id) => {
    try {
      // Delete the group
      const isDeleted = await deleteGroup(id);
      if (isDeleted?.success) {
        console.log("paginationData.currPage", paginationData);
        // let updatedRecords = [...allGroups];
        // let filteredRecords = updatedRecords.filter((item) => item?._id != id);
        // setAllGroupsData([...filteredRecords]);
        // fetchData(
        //   paginationData.currPage > paginationData.totalPages
        //     ? paginationData.currPage - 1
        //     : paginationData.currPage,
        //   10
        // );
        // setPaginationData({
        //   currPage:
        //     paginationData.currPage > paginationData.totalPages
        //       ? paginationData.currPage - 1
        //       : paginationData.currPage,
        //   totalPages: Math.ceil(filteredRecords / 10),
        // });
        fetchData(currentPage, 20, searchText);
        // Update global state and local state
        // dispatch(
        //   setGroupsData({
        //     data: allGroups.filter((item) => item._id !== id),
        //     totalRecords: allGroups.length - 1,
        //   })
        // );

        // // Calculate new total records and pages
        // const newTotalRecords = allGroups.length - 1;
        // const newTotalPages = Math.ceil(newTotalRecords / 10);
        // console.log("newTotalRecords", newTotalRecords);
        // // Determine new current page
        // const currentPage = paginationData.currPage;
        // const maxPage = newTotalPages;
        // const newCurrPage =
        //   newTotalRecords < (currentPage - 1) * 10
        //     ? Math.max(1, currentPage - 1)
        //     : currentPage;

        // // Update pagination state
        // setPaginationData({
        //   currPage: newCurrPage,
        //   totalPages: newTotalPages,
        //   totalRecords: newTotalRecords,
        // });

        // // Fetch data with the new page and page size
        // fetchData(newCurrPage, 10);

        console.log("Group Deleted Successfully!");
        toast.success("Group Deleted Successfully!");
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };
  console.log("paginationData.currPage OUTSIDE", paginationData);

  const editGroup = async (id, newName, newContacts) => {
    console.log(id, newName, newContacts);
    const resp = await editGroup1({
      id: selectedGroupId,
      newName,
      newContacts,
    });
    if (resp?.success) {
      //setUpdateScreen(prevUpdateScreen => !prevUpdateScreen)
      // fetchData(allContacts);

      let updatedRecords = [...allGroups];
      let isFound = updatedRecords.findIndex(
        (item) => item?._id == selectedGroupId
      );
      updatedRecords[isFound] = {
        ...updatedRecords[isFound],
        groupName: newName,
        contacts: newContacts,
      };
      console.log("updatedRecords", updatedRecords);

      setAllGroupsData([...updatedRecords]);
      console.log("Group updated Successfully!");
    } else {
      toast.error(resp.error);
      console.log(resp.error);
    }
  };

  useEffect(() => {
    fetchContacts();
    fetchData(currentPage, 20, searchText);
    setCurrentPage(currentPage);
  }, [currentPage]);

  const columns = [
    {
      title: "No#",
      dataIndex: "No",
    },
    {
      title: "Group Name",
      dataIndex: "groupName",
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
    },
    {
      title: "Members",
      dataIndex: "members",
    },
    {
      title: "Actions",
      dataIndex: "Actions",
    },
  ];

  console.log("groupContacts ===", groupContacts);

  // making data for showing rows in table
  const handleDataForTable = (data, contacts = allContacts) => {
    console.log("allContacts in function", contacts);
    let finalData = [];

    for (let i = 0; i != data.length; i++) {
      finalData.push({
        key: i,
        No: i + 1,
        groupName: data[i]?.groupName,
        createdAt: moment(data[i]?.createdAt).format("DD MMM, YY"),
        members: data[i]?.contacts?.length,
        Actions: (
          <Actionbutton
            onClick={() => setSelectedGrpId(data[i]?._id)}
            handleDelete={handleDelete}
            group={data[i]}
            Contactlist={contacts}
            handleSelected={handleGroupContacts}
            selectedContacts={groupContacts}
            editGroup={editGroup}
          />
        ),
      });
    }

    return finalData;
  };

  useMemo(() => {
    setGroups(handleDataForTable(groupsOrgData, allContacts));
  }, [groupContacts]);

  // search any record
  const getSearchedRecords = async (value) => {
    setSearchText(value);
    await fetchData(1, 20, value);
    // If the search value is empty, reset the table with original records
    // if (!value) {
    //   setGroups(groupsOrgData); // Reset to all original records
    //   return;
    // }

    // try {
    //   // Make the API call to fetch the search results
    //   const resp = await searchGroup(value);
    //   // Parse the response data

    //   if (resp.success) {
    //     // If the search is successful, set the contacts with the search results
    //     const { data } = resp;
    //     console.log("data", data);
    //     setGroups(data); // Set the fetched search result to contacts
    //   } else {
    //     // Handle search error (no results or API error)
    //     console.log("Search failed:", resp.error);
    //     setGroups([]); // Set to empty if no matches or error
    //   }
    // } catch (error) {
    //   console.error("Error while searching:", error);
    //   setGroups([]); // Set to empty if there's an error
    // }
  };
  // const getSearchedRecords = (value) => {
  //   if (value == "" || !value) {
  //     setGroups(groupsOrgData);
  //   } else {
  //     let matched = groupsOrgData.filter((item) =>
  //       item?.groupName?.toLowerCase()?.includes(value.toLowerCase())
  //     );

  //     setGroups(matched);
  //   }
  // };

  // if (loading) {
  //     return (
  //         <TableSkelton />
  //     )
  // }
  const handlePaginationChange = (event, value) => {
    fetchData(value, 20, searchText);
    setCurrentPage(value); // Fetch new data for the selected page
  };

  console.log("totalPages", paginationData?.totalPages);

  return (
    <section className="main inner-main">
      <section className="contact-group-top">
        <Container>
          <Row>
            <Col sm={6} className="mb-3 mb-sm-0">
              <Form.Group className="input-search">
                <Form.Control
                  type="text"
                  placeholder="Search...."
                  onChange={(e) =>
                    getSearchedRecords(e.target.value.trimStart())
                  }
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <div className="btn-sec text-sm-end">
                <Button
                  className="btn-main"
                  onClick={() => addGroup()}
                  style={{ width: width < 600 ? "100%" : "" }}
                >
                  <i className="bi bi-plus"></i> Create Group
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {loading ? (
        <TableSkelton />
      ) : (
        <section className="contact-group-table">
          <Container>
            <Row>
              <Col xs={12} className="mb-5">
                {/* <Grouptable groups={groups} searchQuery={searchQuery} handleDelete={handleDelete} Contactlist={allContacts} handleSelected={handleGroupContacts} selectedContacts={groupContacts} editGroup={editGroup} loading={loading} /> */}
                <Table
                  columns={columns}
                  dataSource={groups}
                  scroll={{
                    x: width < 600 && 1200,
                  }}
                  pagination={false}
                  // pagination={{
                  //   total: paginationData?.totalPages,
                  //   onChange: (page, pageSize) => {
                  //     fetchData(page);
                  //     console.log("data changed : ", page, pageSize);
                  //   },
                  // }}
                  loading={isFetchingMore}
                />
              </Col>
            </Row>
          </Container>

          <Box
            className="bg-[#FFFFFF] flex justify-center align-items-center w-100 all-tags "
            sx={{
              height: "10%",
              position: "fixed",
              bottom: "0",
              boxShadow: "0 -4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* <Pagination
                count={Math.ceil((totalRecords / 10) % 10)}
                //count={Math.ceil((props?.totalRecords / 10) % 10)}
                variant="outlined"
                shape="rounded"
                siblingCount={0}
                page={paginationData?.currPage}
                //page={props?.currPage == 0 ? 1 : props?.currPage}
                onChange={handlePaginationChange}
              />
               */}
            {groups?.length > 0 && (
              <Pagination
                count={paginationData.totalPages} // Total number of pages
                variant="outlined"
                shape="rounded"
                siblingCount={0}
                page={paginationData.currPage} // Current page
                onChange={handlePaginationChange} // Page change handler
              />
            )}
          </Box>
        </section>
      )}

      <Modal
        show={cgshow}
        onHide={() => setCgShow(false)}
        className="whatsapp-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Create Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Group Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Set Group Name i.e Coworkers, Employees"
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button className="btn-main-default" onClick={() => setCgShow(false)}>
            Cancel
          </Button>
          <Button
            className="btn-main"
            onClick={() => {
              setCgShow(false);
              setCgtShow(true);
            }}
          >
            Next
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={cgtshow && allContacts.length > 0}
        onHide={() => setCgtShow(false)}
        className="whatsapp-modal modal-g-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title className="mb-1 w-100">
            Add Group Members
            <span
              className="finish-btn"
              onClick={() => {
                //if (selectedContacts.length > 0) {
                setCgtShow(false);
                setNewgroupShow(true);
                setShowError(false);
                // }
                // else {
                //     setShowError(true)
                // }
              }}
            >
              Next <i className="bi bi-chevron-right"></i>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Row>
            <Col sm={12}>
              {/* <Button className = "import-cvs" onClick={()=>{setCgtShow(false) 
                                setCvsShow(true)}}>
                                Import Contacts From CSV File
                            </Button> */}
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Search contact to add in group"
                  onChange={(e) => setSearchQuery1(e.target.value.trimStart())}
                />
              </Form.Group>
            </Col>
          </Row>
          {showError && (
            <span style={{ color: "red", fontWeight: "500" }}>
              Select atleast 1 contact *
            </span>
          )}
          <Row>
            <div
              className="col-12"
              style={{
                maxHeight: width < 600 ? "300px" : "",
                height: width < 600 ? "100%" : "",
                overflow: width < 600 ? "auto" : "",
              }}
            >
              {isContactsFetching ? (
                <p>Fetching Contacts please wait...</p>
              ) : (
                <Allcontacts
                  Contactlist={allContacts}
                  handleSelected={handleSelected}
                  selectedContacts={selectedContacts}
                  searchQuery={searchQuery1}
                />
              )}
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={newgroupshow}
        onHide={() => setNewgroupShow(false)}
        className="whatsapp-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0" closeButton>
          <Modal.Title className="text-center w-100">
            <div
              className="back-btn"
              onClick={() => {
                setNewgroupShow(false);
                setCgtShow(true);
              }}
            >
              <i className="bi bi-chevron-left"></i>
            </div>
            New Group
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Group Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Set Group Name i.e Coworkers, Employees"
                  onChange={handleInputChange}
                  value={groupName}
                  required
                />
                {showError1 && (
                  <span style={{ color: "red", fontWeight: "500" }}>
                    Group name cannot be empty *
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>
                  Participants: {selectedContacts.length} of{" "}
                  {allContacts.length}
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="px-0">
          {isCreating ? (
            <div
              style={{
                display: "flex",
                minWidth: "150px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ color: "rgba(22, 94, 91, 1)" }}
              />
            </div>
          ) : (
            <Button
              className="btn-main"
              onClick={() => {
                if (groupName.length > 0) {
                  // setNewgroupShow(false);
                  setShowError1(false);
                  createNewGroup();
                } else {
                  setShowError1(true);
                }
              }}
            >
              Create Group
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={cvsshow}
        onHide={() => setCvsShow(false)}
        className="whatsapp-modal modal-csv"
        centered
      >
        <Modal.Header className="p-0 border-0" closeButton>
          <Modal.Title className="w-100 text-center">
            <div
              className="back-btn"
              onClick={() => {
                setCvsShow(false);
                setNewgroupShow(true);
              }}
            >
              <i className="bi bi-chevron-left"></i>
            </div>
            Import contacts from CSV File
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Row className="mb-1">
            <Col sm={12}>
              <Form.Group controlId="formFile" className="position-relative">
                <Form.Control className="file-up" type="file" ref={inputRef} />
                <div className="input-label" onClick={handleClick}>
                  Upload
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="modal-table">
                <Modaltable />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Button className="import-cvs">
                Import Contacts From CSV File
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            className="btn-main-default"
            onClick={() => setCvsShow(false)}
          >
            Cancel
          </Button>
          <Button className="btn-main" onClick={() => setCvsShow(false)}>
            Import Contact
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default Contact;
