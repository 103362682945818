import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Username from "../userName/username";

// import UsertableStatus from "./user-status";
// import Useraction from "./useraction";

// import "../manageUser/css/usermob.css";

const ContactMobile = ({ contacts }) => {
  console.log("contacts", contacts);
  return (
    <>
      <Container className="">
        <Row>
          <Col xs={12}>
            <div className="template-listing-collapse">
              <div className="tlc-header">Name</div>
              <div className="tlc-body">
                <Accordion defaultActiveKey="0">
                  {contacts?.map((contact, idx) => {
                    return (
                      <Accordion.Item eventKey={idx}>
                        <Accordion.Header>
                          <Username
                            nameletter={contact?.name}
                            name={contact?.name}
                            index={idx}
                          />
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="contact-list d-flex align-items-center justify-content-between mb-3">
                            <div className="ub-title">Phone Number</div>
                            <div className="phone_number">{contact.number}</div>
                          </div>
                          <div className="contact-list d-flex align-items-center justify-content-between mb-3">
                            <div className="ub-title">Created At</div>
                            <div className="cb-date">{contact.createdAt}</div>
                          </div>
                          <div className="d-flex align-items-center justify-content-end">
                            {/* <Actionbutton /> */}
                            {contact?.Actions}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactMobile;
