import axios from "axios";
import config from "../../config";

const baseUrl = `${config.backend}contact/`;

export const getAllContacts = async (page = 1, limit = 10) => {
  const response = await axios.get(`${baseUrl}all?page=${page}&limit=${limit}`);
  return response.data;
};
export const searchContact = async (page = 1, limit = 10, value = "") => {
  const response = await axios.get(
    `${baseUrl}getAllBySearch?page=${page}&limit=${limit}&searchKey=${value}`
  );
  return response.data;
};
export const getSpecificContacts = async (id) => {
  const response = await axios.get(`${baseUrl}${id}`);
  return response.data;
};

export const deleteContacts = async (id) => {
  const response = await axios.delete(`${baseUrl}${id}`);
  return response.data;
};

export const updatetheContact = async (data) => {
  const response = await axios.put(`${baseUrl}updateContact`, data);
  return response.data;
};

export const addContact = async (data) => {
  const response = await axios.post(`${baseUrl}add`, data);
  return response.data;
};

export const addMultipleContacts = async (data) => {
  const response = await axios.post(`${baseUrl}add-multiple`, data);
  return response.data;
};
