import React, { useState, useRef, useMemo } from 'react'
import { Box, IconButton, Typography, Button as MuiButton } from "@mui/material"
import Select from 'react-select';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { Row, Col, Form, Button, Accordion } from "react-bootstrap"
import CallToAction from '../../../components/template-buttons/CallToAction'
import QuickReply from '../../../components/template-buttons/QuickReply'

import imageIcon from "../../../images/image-small-icon.svg"
import documentIcon from "../../../images/document-small-icon.svg"
import videoIcon from "../../../images/video-small-icon.svg"
import locationIcon from "../../../images/location-small-icon.svg"
import smallImageIcon from "../../../images/image-icon.svg"
import smallDocIcon from "../../../images/document-icon.svg"
import smallVideoIcon from "../../../images/video-icon.svg"

export const TemplateCategory = ({ formData, handleChange }) => {
    return (
        <>
            <Box className="flex justify-start items-start flex-column border-[1px] border-dashed rounded-md border-[#77777780] p-[10px] lg:p-[20px] bg-[#FFFFFF]">
                <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[14px] lg:text-[16px] font-medium leading-[22px] text-[#000000]" >Template Category</Typography>
                <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[15px] font-thin leading-[18px] text-[#494949] mt-[8px] " >Choose a category below.</Typography>
                <Box className="button-select mt-[16px] ">
                    <Box className="form-check form-check-inline" onClick={() => { }}>
                        <input disabled={formData?.isApproved} className="form-check-input" type="radio" id="inline-radio-1" name="category" value="UTILITY" onChange={handleChange} checked={formData?.category === "UTILITY"} />
                        <label className="form-label" htmlFor="inline-radio-1">Utility</label>
                    </Box>
                    <Box className="form-check form-check-inline" onClick={() => { }}>
                        <input disabled={formData?.isApproved} className="form-check-input" type="radio" id="inline-radio-2" name="category" value="MARKETING" onChange={handleChange} checked={formData?.category === "MARKETING"} />
                        <label className="form-label" htmlFor="inline-radio-2">Marketing</label>
                    </Box>
                    <Box className="form-check form-check-inline" onClick={() => { }}>
                        <input disabled={formData?.isApproved} className="form-check-input" type="radio" id="inline-radio-3" name="category" value="AUTHENTICATION" onChange={handleChange} checked={formData?.category === "AUTHENTICATION"} />
                        <label className="form-label" htmlFor="inline-radio-3">Authentication</label>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export const TemplateNameSection = ({ isEdit, formData, handleChange, handleLanguage, tempData }) => {
    const languageOptions = [
        { value: 'en_US', label: 'English (US)' },
        { value: 'en_GB', label: 'English (UK)' },
    ];
    return (
        <>
            <Box className="flex justify-start items-start flex-column border-[1px] border-dashed rounded-md border-[#77777780] p-[10px] lg:p-[20px] bg-[#FFFFFF] mt-[18px] templates-section">
                <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[14px] lg:text-[16px] font-medium leading-[22px] text-[#000000]" >Name</Typography>
                <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[15px] font-thin leading-[18px] text-[#494949] mt-[8px] " >Give a name to your template.</Typography>
                <Form.Group className="form-group w-full mt-[12px]">
                    <Form.Control readOnly={isEdit} style={{ marginBottom: "0px", height: "40px", backgroundColor: "#F3F7F7" }} type="text" placeholder="My Template" isInvalid={formData.name.length > 60 || formData.name && !(/^(?! )[a-z_][a-z_]*$/g.test(formData.name))} onChange={handleChange} name="name" value={formData.name} />
                    <Form.Control.Feedback style={{ marginBottom: "0px", width: "100%", justifyContent: "space-between", display: "flex", }} type="invalid">
                        <p style={{ fontSize: "12px" }}>{(!(/^(?! )[a-z_][a-z_]*$/g.test(formData.name)) && formData.name.length > 0) ? "Name can only have lowercase and underscore" : formData.name.length > 60 ? "Not more than 60 character allowed" : ""}</p>
                        <span style={{ color: "black" }} >{formData.name.length}/60</span>
                    </Form.Control.Feedback>
                </Form.Group>

                <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[14px] lg:text-[16px] font-medium leading-[22px] text-[#000000]" >Languages</Typography>
                <Select className="c-select mt-[12px]" isDisabled={isEdit} value={tempData.language} onChange={handleLanguage} options={languageOptions} placeholder="Select a language" />
            </Box>
        </>
    )
}

export const TemplateHeader = (props) => {
    const headerOptions = [
        { value: 'none', label: 'None' },
        { value: 'text', label: 'Text' },
        { value: 'media', label: 'Media' },
    ];

    useMemo(() => {
        if (props?.headerData?.type == "none") {
            props?.setTempImageUrl(null)
        }
    }, [props?.headerData?.type])

    const [selectedMediaType, setSelectedMediaType] = useState('image')
    const imageRef = useRef(null);
    const videoRef = useRef(null);
    const documentRef = useRef(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        props?.setHeaderData(prev => ({ ...prev, file: file }))
        const url = URL.createObjectURL(file);
        props?.setTempImageUrl(url)
    };

    const handleVideoChange = (event) => {
        const file = event.target.files[0];
        console.log("name of file : ", file)
        props?.setTempImageUrl(null)
        props?.setHeaderData(prev => ({ ...prev, file: file }))
    };

    const handleDocumentChange = (event) => {
        const file = event.target.files[0];
        console.log("name of file : ", file)
        props?.setTempImageUrl(null)
        props?.setHeaderData(prev => ({ ...prev, file: file }))
    };

    const handleCloseBtnClick = () => {
        props?.setTempImageUrl(null)
        props?.setHeaderData(prev => ({ ...prev, file: null }))
    }


    return (
        <>
            <Box className="flex justify-start items-start flex-column border-[1px] border-dashed rounded-md border-[#77777780] p-[10px] lg:p-[20px] bg-[#FFFFFF] mt-[18px] templates">
                <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[14px] lg:text-[16px] font-medium leading-[22px] text-[#000000]" >Header</Typography>
                <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[15px] font-thin leading-[18px] text-[#494949] mt-[8px] " >Add a title or choose which type of media you’ll use for this  header.</Typography>
                <Select className="c-select mt-[12px]" options={headerOptions} placeholder="Select a Type" isDisabled={props?.isEdit} value={headerOptions?.find(item => item?.value == props?.headerData?.type)} onChange={(val) => props?.setHeaderData(prev => ({ ...prev, type: val?.value }))} />

                {/* in case user has elected text as header */}
                {
                    props?.headerData?.type == "none" ?
                        null :
                        <>
                            {
                                props?.headerData?.type == "text" ?
                                    <>
                                        <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[14px] lg:text-[16px] font-medium leading-[22px] text-[#000000] mt-[22px]" >Header Text</Typography>
                                        <Form.Group className="form-group w-full mt-[12px]">
                                            <Form.Control style={{ marginBottom: "0px", background: "#D9D9D999", height: "40px" }} type="text" placeholder="Header Name" name="header" onChange={props?.handleChange} />
                                            <Form.Control.Feedback style={{ marginBottom: "0px", width: "100%", justifyContent: "space-between", display: "flex" }} type="invalid">
                                                <p style={{ fontSize: "12px" }}>{(!(/^(?! )[a-z_][a-z_]*$/g.test(props?.formData?.header)) && props?.formData?.header.length > 0) ? "Name can only have lowercase and underscore" : props?.formData?.header.length > 60 ? "Not more than 60 character allowed" : ""}</p>
                                                <span style={{ color: "black" }} >{props?.formData?.header?.length}/60</span>
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </>
                                    : null
                            }

                            {
                                props?.headerData?.type == "media" ?
                                    <>
                                        {/* in case user has selected file type */}
                                        <Box className="flex justify-start items-start flex-wrap gap-2 mt-4" >
                                            <Box className="flex justify-start items-start flex-col border-[1px] border-[#165E5B66] w-[110px] min-h-[110px] rounded-md " sx={{ cursor: "pointer", backgroundColor: selectedMediaType == "image" ? "#E5FFF1" : "#FFFFFF" }} onClick={() => { setSelectedMediaType('image'); props?.setHeaderData(prev => ({ ...prev, fileType: "image", file: null })) }} >
                                                <Box className="flex justify-start items-center pl-[10px] pt-[5px]" >
                                                    <Box className="form-check form-check-inline " >
                                                        <input className="form-check-input" type="radio" id="header-type-1" checked={selectedMediaType == "image"} />
                                                        <label className="form-label" htmlFor="header-type-1"></label>
                                                    </Box>
                                                </Box>
                                                <Box className="flex justify-center items-center flex-col w-100 gap-2 pb-2" >
                                                    <img src={imageIcon} style={{ width: "44px", height: "44px", }} />
                                                    <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-medium leading-[22px] text-[#000000]" >Image</Typography>
                                                </Box>
                                            </Box>
                                            <Box className="flex justify-start items-start flex-col border-[1px] border-[#165E5B66] w-[110px] min-h-[110px] rounded-md " sx={{ cursor: "pointer", backgroundColor: selectedMediaType == "video" ? "#E5FFF1" : "#FFFFFF" }} onClick={() => { setSelectedMediaType('video'); props?.setHeaderData(prev => ({ ...prev, fileType: "video", file: null })) }} >
                                                <Box className="flex justify-start items-center pl-[10px] pt-[5px]" >
                                                    <Box className="form-check form-check-inline " >
                                                        <input className="form-check-input" type="radio" id="header-type-1" checked={selectedMediaType == "video"} />
                                                        <label className="form-label" htmlFor="header-type-1"></label>
                                                    </Box>
                                                </Box>
                                                <Box className="flex justify-center items-center flex-col w-100 gap-2 pb-2" >
                                                    <img src={videoIcon} style={{ width: "44px", height: "44px", }} />
                                                    <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-medium leading-[22px] text-[#000000]" >Video</Typography>
                                                </Box>
                                            </Box>
                                            <Box className="flex justify-start items-start flex-col border-[1px] border-[#165E5B66] w-[110px] min-h-[110px] rounded-md " sx={{ cursor: "pointer", backgroundColor: selectedMediaType == "document" ? "#E5FFF1" : "#FFFFFF" }} onClick={() => { setSelectedMediaType('document'); props?.setHeaderData(prev => ({ ...prev, fileType: "document", file: null })) }} >
                                                <Box className="flex justify-start items-center pl-[10px] pt-[5px]" >
                                                    <Box className="form-check form-check-inline " >
                                                        <input className="form-check-input" type="radio" id="header-type-1" checked={selectedMediaType == "document"} />
                                                        <label className="form-label" htmlFor="header-type-1"></label>
                                                    </Box>
                                                </Box>
                                                <Box className="flex justify-center items-center flex-col w-100 gap-2 pb-2" >
                                                    <img src={documentIcon} style={{ width: "44px", height: "44px", }} />
                                                    <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-medium leading-[22px] text-[#000000]" >Document</Typography>
                                                </Box>
                                            </Box>
                                            <Box className="flex justify-start items-start flex-col border-[1px] border-[#165E5B66] w-[110px] min-h-[110px] rounded-md " sx={{ cursor: "pointer", backgroundColor: selectedMediaType == "location" ? "#E5FFF1" : "#FFFFFF" }} onClick={() => { setSelectedMediaType('location'); props?.setHeaderData(prev => ({ ...prev, fileType: "location", file: null })) }} >
                                                <Box className="flex justify-start items-center pl-[10px] pt-[5px]" >
                                                    <Box className="form-check form-check-inline " >
                                                        <input className="form-check-input" type="radio" id="header-type-1" checked={selectedMediaType == "location"} />
                                                        <label className="form-label" htmlFor="header-type-1"></label>
                                                    </Box>
                                                </Box>
                                                <Box className="flex justify-center items-center flex-col w-100 gap-2 pb-2" >
                                                    <img src={locationIcon} style={{ width: "44px", height: "44px", }} />
                                                    <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-medium leading-[22px] text-[#000000]" >Location</Typography>
                                                </Box>
                                            </Box>
                                        </Box>

                                        {/* if option of image is selected for file */}
                                        {
                                            selectedMediaType == "image" ?
                                                <Box className="flex justify-start items-start flex-col w-100 gap-[10px] mt-[30px]" sx={{ cursor: "pointer" }} >
                                                    <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[14px] lg:text-[16px] font-medium leading-[22px] text-[#000000]" >Upload image for the header</Typography>
                                                    <Box className="flex justify-start items-center w-[30%] gap-[10px]" >
                                                        <Box className="flex justify-start items-center gap-2  border-[1px] border-[#BDC8D4] rounded-md p-[11px]" onClick={() => props?.headerData?.file == null ? imageRef.current.click() : null} >
                                                            <img src={smallImageIcon} style={{ width: "14px", height: "14px" }} />
                                                            <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-medium leading-[22px] text-[#4B4A4A]" >{props?.headerData?.file ? props?.headerData?.file?.name : "Choose a JPG or PNG file"}</Typography>
                                                        </Box>
                                                        {
                                                            props?.headerData?.file != null ?
                                                                <IconButton sx={{ padding: "0px" }} onClick={handleCloseBtnClick} >
                                                                    <CloseIcon sx={{ fontSize: "20px", color: "#000000", }} />
                                                                </IconButton>
                                                                : null
                                                        }
                                                    </Box>
                                                    <input
                                                        ref={imageRef}
                                                        onChange={handleImageChange}
                                                        type="file"
                                                        accept="image/*"
                                                        style={{ display: 'none' }}
                                                    />
                                                </Box>
                                                : null
                                        }

                                        {/* if option of video is selected for file */}
                                        {
                                            selectedMediaType == "video" ?
                                                <Box className="flex justify-start items-start flex-col w-100 gap-[10px] mt-[30px]" sx={{ cursor: "pointer" }} >
                                                    <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[14px] lg:text-[16px] font-medium leading-[22px] text-[#000000]" >Upload video for the header</Typography>
                                                    <Box className="flex justify-start items-center w-[30%] gap-[10px]" onClick={() => props?.headerData?.file == null ? videoRef.current.click() : null} >
                                                        <Box className="flex justify-start items-center gap-2  border-[1px] border-[#BDC8D4] rounded-md p-[11px]" >
                                                            <img src={smallVideoIcon} style={{ width: "14px", height: "14px" }} />
                                                            <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-medium leading-[22px] text-[#4B4A4A]" >{props?.headerData?.file ? props?.headerData?.file?.name : "Choose MP4 file"}</Typography>
                                                        </Box>
                                                        {
                                                            props?.headerData?.file != null ?
                                                                <IconButton sx={{ padding: "0px" }} onClick={handleCloseBtnClick} >
                                                                    <CloseIcon sx={{ fontSize: "20px", color: "#000000", }} />
                                                                </IconButton>
                                                                : null
                                                        }
                                                        <input
                                                            ref={videoRef}
                                                            onChange={handleVideoChange}
                                                            type="file"
                                                            accept="video/*"
                                                            style={{ display: 'none' }}
                                                        />
                                                    </Box>
                                                </Box>
                                                : null
                                        }

                                        {/* if option of document is selected for file */}
                                        {
                                            selectedMediaType == "document" ?
                                                <Box className="flex justify-start items-start flex-col w-100 gap-[10px] mt-[30px]" sx={{ cursor: "pointer" }} onClick={() => props?.headerData?.file == null ? documentRef.current.click() : null} >
                                                    <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[14px] lg:text-[16px] font-medium leading-[22px] text-[#000000]" >Upload PDF for the header</Typography>
                                                    <Box className="flex justify-start items-center w-[30%] gap-[10px]" >
                                                        <Box className="flex justify-start items-center gap-2  border-[1px] border-[#BDC8D4] rounded-md p-[11px]" >
                                                            <img src={smallDocIcon} style={{ width: "14px", height: "14px" }} />
                                                            <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-medium leading-[22px] text-[#4B4A4A]" >{props?.headerData?.file ? props?.headerData?.file?.name : "Choose a PDF file"}</Typography>
                                                        </Box>
                                                        {
                                                            props?.headerData?.file != null ?
                                                                <IconButton sx={{ padding: "0px" }} >
                                                                    <CloseIcon sx={{ fontSize: "20px", color: "#000000", }} />
                                                                </IconButton>
                                                                : null
                                                        }
                                                        <input
                                                            ref={documentRef}
                                                            onChange={handleDocumentChange}
                                                            type="file"
                                                            accept="application/pdf"
                                                            style={{ display: 'none' }}
                                                        />
                                                    </Box>
                                                </Box>
                                                : null
                                        }

                                        {/* if option of location is selected for file */}
                                        {
                                            selectedMediaType == "location" ?
                                                <Box className="flex justify-start items-start flex-col w-100 gap-[10px] mt-[30px]" sx={{ cursor: "pointer" }} >
                                                    <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[14px] lg:text-[16px] font-medium leading-[22px] text-[#000000] mt-[22px]" >Location Name</Typography>
                                                    <Form.Group className="form-group w-full mt-[12px]">
                                                        <Form.Control style={{ marginBottom: "0px", background: "#D9D9D999", height: "40px" }} type="text" placeholder="Enter Name" name="name" />
                                                    </Form.Group>

                                                    <Box className="flex justify-between items-baseline gap-[30px] w-100 mt-0" >
                                                        <Box className="flex justify-start items-start flex-col w-100 " >
                                                            <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[14px] lg:text-[16px] font-medium leading-[22px] text-[#000000] mt-[22px]" >Latitude</Typography>
                                                            <Form.Group className="form-group w-full mt-[12px]">
                                                                <Form.Control style={{ marginBottom: "0px", background: "#D9D9D999", height: "40px" }} type="text" placeholder="Enter Latitude" name="name" />
                                                            </Form.Group>
                                                        </Box>
                                                        <Box className="flex justify-start items-start flex-col w-100 " >
                                                            <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[14px] lg:text-[16px] font-medium leading-[22px] text-[#000000] mt-[22px]" >Longitude</Typography>
                                                            <Form.Group className="form-group w-full mt-[12px]">
                                                                <Form.Control style={{ marginBottom: "0px", background: "#D9D9D999", height: "40px" }} type="text" placeholder="Enter Longitude" name="name" />
                                                            </Form.Group>
                                                        </Box>
                                                    </Box>

                                                    <Box className="flex justify-start items-start w-100 mt-[26px]" >
                                                        <MuiButton variant="contained" disableRipple sx={{ background: "#165E5B", color: "#FFFFFF", borderRadius: "5px", height: "45px", textTransform: "none", fontWeight: 600, fontSize: "16px", fontFamily: "Segoe UI", boxShadow: 'none', '&:hover': { backgroundColor: "#165E5B" } }} >Add Location From Map</MuiButton>
                                                    </Box>
                                                </Box>
                                                : null
                                        }
                                    </>
                                    : null
                            }

                        </>
                }

            </Box>
        </>
    )
}

export const TemplateBody = ({ handleChange, formData, handlebodyVarCount, bodySample, bodyVarCount, handleBodySample }) => {
    return (
        <>
            <Box className="flex justify-start items-start flex-column border-[1px] border-dashed rounded-md border-[#77777780] p-[10px] lg:p-[20px] bg-[#FFFFFF] mt-[18px] templates">
                <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[14px] lg:text-[16px] font-medium leading-[22px] text-[#000000] " >Body</Typography>
                <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[15px] font-thin leading-[18px] text-[#494949] mt-[8px] mb-[12px]" >Enter the text for your message in the language you’ve selected.</Typography>
                <textarea className="form-control w-100" placeholder="Body text" style={{ resize: "none" }} rows={"6"} value={formData.body} onChange={handleChange} name="body"></textarea>
                <Form.Control.Feedback style={{ width: "100%", justifyContent: "space-between", display: "flex" }} type="invalid">
                    <Box className="flex justify-end items-center w-100 gap-2" >
                        <p style={{ fontSize: "12px" }}>{(formData.body.length > 1024) ? "Not more than 1024 character allowed" : ""}</p>
                        <button className="var-button" onClick={handlebodyVarCount} >Add Variable</button>
                        <span style={{ color: "black" }} >{formData.body.length}/1024</span>
                    </Box>
                </Form.Control.Feedback>

                {bodyVarCount > 0 && <Accordion className="accordian" defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Example for Body variables</Accordion.Header>
                        <Accordion.Body>
                            {bodySample.map((bodyVar, index) => (
                                <Form.Group className="form-group" key={index}>
                                    <div className="label-bottom" style={{ marginBottom: '5px' }}>
                                        Add example for body variable {index + 1}.
                                    </div>
                                    <Form.Control
                                        placeholder="Example Text"
                                        onChange={event => handleBodySample(event, index)}
                                        value={bodyVar}
                                    />
                                </Form.Group>
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>}
            </Box>
        </>
    )
}

export const TemplateVariables = (props) => {
    return (
        <>
            <Box className="flex justify-start items-start flex-column border-[1px] border-dashed rounded-md border-[#77777780] p-[10px] lg:p-[20px] bg-[#FFFFFF] mt-[18px] templates">
                <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[14px] lg:text-[16px] font-medium leading-[22px] text-[#000000] " >Add Variables</Typography>
                <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[15px] font-thin leading-[18px] text-[#494949] mt-[8px] mb-[12px]" >Please use the following preset variables in the 'Body' if you need them:</Typography>

                {/* already existing variables */}
                <Box className="flex justify-start items-center gap-1 mt-[10px]" >
                    <Box className="flex justify-center items-center bg-[#D6F8E5] p-[6px] rounded-[5px] gap-2 " >
                        <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-[400] leading-[18px] text-[#000000] " >{`{{Full_Name}}`}</Typography>
                        <IconButton sx={{ padding: "0px" }} >
                            <ContentCopyIcon sx={{ fontSize: "20px", color: "#000000", }} />
                        </IconButton>
                    </Box>
                    <Box className="flex justify-center items-center bg-[#D6F8E5] p-[6px] rounded-[5px] gap-2 " >
                        <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-[400] leading-[18px] text-[#000000] " >{`{{Phone Number}}`}</Typography>
                        <IconButton sx={{ padding: "0px" }} >
                            <ContentCopyIcon sx={{ fontSize: "20px", color: "#000000", }} />
                        </IconButton>
                    </Box>
                </Box>

                <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[15px] font-thin leading-[18px] text-[#494949] mt-[10px] lg:mt-[20px]" >Please use the following input box to create your custom variables.</Typography>
                <Box className="flex justify-start items-center gap-1 flex-column mt-[10px] w-100" >
                    <Box className="flex justify-start items-center gap-2 w-100" >
                        <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[14px] lg:text-[16px] font-[400] leading-[18px] text-[#494949] " >{`{{1}}`}</Typography>
                        <Box className="flex justify-between items-center bg-[#D9D9D999] border-[1px] border-[#D9D9D999] rounded-[5px] h-[40px] p-[10px]" sx={{ width: "100%" }} >
                            <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-[600] leading-[18px] text-[#7D7D7D] " >Address</Typography>
                            <IconButton sx={{ padding: "0px" }} >
                                <CloseIcon sx={{ fontSize: "20px", color: "#7D7D7D", }} />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>

                <Box className="flex justify-end items-center gap-2 w-100 mt-[15px]" >
                    <IconButton sx={{ padding: "0px" }} >
                        <AddIcon sx={{ fontSize: "20px", color: "#000000", }} />
                    </IconButton>
                    <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-[500] leading-[18px] text-[#000000] " >Add more</Typography>
                </Box>
            </Box>
        </>
    )
}

export const TemplateFooter = ({ handleChange, formData }) => {
    return (
        <>
            <Box className="flex justify-start items-start flex-column border-[1px] border-dashed rounded-md border-[#77777780] p-[10px] lg:p-[20px] bg-[#FFFFFF] mt-[18px] templates">
                <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[14px] lg:text-[16px] font-medium leading-[22px] text-[#000000]" >Footer</Typography>
                <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[15px] font-thin leading-[18px] text-[#494949] mt-[8px] " >Add a short line of text to the bottom of your message template.</Typography>
                <Form.Group className="form-group w-full mt-[12px]">
                    <Form.Control style={{ marginBottom: "0px", background: "#D9D9D999", height: "40px" }} placeholder="Footer text" onChange={handleChange} name="footer" value={formData?.footer} />
                    <Form.Control.Feedback style={{ marginBottom: "0px", width: "100%", justifyContent: "space-between", display: "flex" }} type="invalid">
                        <p style={{ fontSize: "12px" }}>{(!(/^(?! )[a-z_][a-z_]*$/g.test(formData?.name)) && formData?.name.length > 0) ? "Name can only have lowercase and underscore" : formData?.name.length > 60 ? "Not more than 60 character allowed" : ""}</p>
                        <span style={{ color: "black" }} >{formData?.name.length}/60</span>
                    </Form.Control.Feedback>
                </Form.Group>
            </Box>
        </>
    )
}

export const TemplateActionButtons = ({ tempData, handleButton, buttonType, quickReplyData, handleQuickReply, handleCallToAction, deleteQuickReply, numQuickReplyButtons, numcallToActionButtons, AddQuickReplyButton, AddCallToActionButton, callToActionData, deleteCallToAction, toggleAction }) => {
    return (
        <>
            <Box className="flex justify-start items-start flex-column border-[1px] border-dashed rounded-md border-[#77777780] p-[10px] lg:p-[20px] bg-[#FFFFFF] mt-[18px] templates">
                <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[14px] lg:text-[16px] font-medium leading-[22px] text-[#000000]" >Buttons</Typography>
                <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[15px] font-thin leading-[18px] text-[#494949] mt-[8px] " >Choose a button that let users interact to your message.</Typography>
                <Box className="button-select mt-[16px] ">
                    <Box className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" id="inline-radio-1" value="none" onChange={() => handleButton({ "value": "None", "label": "None" })} checked={tempData.button?.value === "None"} />
                        <label className="form-label" htmlFor="inline-radio-1">None</label>
                    </Box>
                    <Box className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" id="inline-radio-2" value="Call To Action" onChange={() => handleButton({ "value": "calltoaction", "label": "Call to Action" })} checked={tempData.button?.value === "calltoaction"} />
                        <label className="form-label" htmlFor="inline-radio-2">Call to Action</label>
                    </Box>
                    <Box className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" id="inline-radio-3" value="Qucik Reply" onChange={() => handleButton({ "value": "quickreply", "label": "Quick Reply" })} checked={tempData.button?.value === "quickreply"} />
                        <label className="form-label" htmlFor="inline-radio-3">Quick Reply</label>
                    </Box>
                </Box>

                {buttonType === "quickreply" && (
                    <Row>
                        <Col sm={12}>
                            <label className="form-label">Quick Reply</label>
                            <div className="button-details">
                                {quickReplyData.map(item => (
                                    <div style={{ height: "70px", display: "flex" }}>
                                        <QuickReply
                                            style={{ width: "auto" }}
                                            handleQuickReply={handleQuickReply}
                                            data={item.data}
                                            dataKey={item.key}
                                            deleteQuickReply={deleteQuickReply}
                                            key={item.key}
                                            quickReplyData={quickReplyData}
                                        />

                                    </div>
                                ))}
                                {numQuickReplyButtons < 3 && <Button className="add-btn" onClick={AddQuickReplyButton}>
                                    <i className="bi bi-plus"></i> Add More
                                </Button>}
                            </div>
                        </Col>
                    </Row>
                )}

                {buttonType === "calltoaction" && (
                    <Row>
                        <Col sm={12}>
                            <label className="form-label">Call To Action</label>
                            <div className="button-details call-to-action-details">
                                {callToActionData.map(item => (

                                    <CallToAction
                                        style={{ width: "auto" }}
                                        handleCallToAction={handleCallToAction}
                                        data={item}
                                        dataKey={item.key}
                                        deleteCallToAction={deleteCallToAction}
                                        toggleAction={toggleAction}
                                        key={item.key}
                                    />


                                ))}

                                {numcallToActionButtons < 2 && <Button className="add-btn" onClick={AddCallToActionButton}>
                                    <i className="bi bi-plus"></i> Add More
                                </Button>}
                            </div>
                        </Col>
                    </Row>
                )}
            </Box>
        </>
    )
}