import React from "react";
import useDeviceWidthDetector from "../customHooks/DeviceWidthDetector";

const Userlist = ({
  id,
  Image,
  Name,
  Messagetitle,
  Time,
  isSelected,
  date,
  handleButtonClick,
  setId,
  unreadMessageCount,
  ChatTag,
  TagClass,
  MessageNumber,
  UserClass,
  activeId,
  isMuted,
  setNewChatId,
  setSelectedContact,
  setSelectedTemplate,
}) => {
  const width = useDeviceWidthDetector();
  function handleClick() {
    console.log("sssss", id);
    handleButtonClick(id);
    // setNewChatId("");
    setSelectedContact(null);
    setSelectedTemplate(null);
    //setId(id)
  }

  console.log("tag got in comp : ", Name, ":", ChatTag);
  return (
    <div
      className={`single-user ${UserClass}`}
      onClick={handleClick}
      style={{
        backgroundColor: activeId === id ? " #E9F2FF" : "",
      }}
    >
      <div className="user-details">
        <div className="user-image">
          <img src={Image} alt="user-img" style={{ maxWidth: "none" }} />
          {/* <span className="online-status"></span> */}
        </div>

        <div className="user-content w-100">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <div className="user-name">{Name}</div>
              <div className="msg-title">{Messagetitle}</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                columnGap: "5px",
              }}
            >
              {isMuted ? (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.33317 15.8327V14.166H4.99984V8.33268C4.99984 7.87435 5.05887 7.42296 5.17692 6.97852C5.29498 6.53407 5.47206 6.11046 5.70817 5.70768L8.33317 8.33268H5.99984L2.1665 4.49935L3.33317 3.33268L16.6665 16.666L15.4998 17.8327L13.4582 15.8327H3.33317ZM14.9998 12.6243L6.83317 4.45768C7.11095 4.23546 7.40956 4.04102 7.729 3.87435C8.04845 3.70768 8.38873 3.58268 8.74984 3.49935V2.91602C8.74984 2.56879 8.87137 2.27365 9.11442 2.0306C9.35748 1.78754 9.65262 1.66602 9.99984 1.66602C10.3471 1.66602 10.6422 1.78754 10.8853 2.0306C11.1283 2.27365 11.2498 2.56879 11.2498 2.91602V3.49935C12.3609 3.77713 13.2637 4.36393 13.9582 5.25977C14.6526 6.1556 14.9998 7.1799 14.9998 8.33268V12.6243ZM9.99984 18.3327C9.5415 18.3327 9.14914 18.1695 8.82275 17.8431C8.49637 17.5167 8.33317 17.1243 8.33317 16.666H11.6665C11.6665 17.1243 11.5033 17.5167 11.1769 17.8431C10.8505 18.1695 10.4582 18.3327 9.99984 18.3327Z"
                    fill="#5F6368"
                  />
                </svg>
              ) : null}
              {MessageNumber > 0 ? (
                <div className="chat-msg-badge">{MessageNumber}</div>
              ) : null}
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between pt-3">
            {ChatTag ? (
              <div
                className={`chat-tag `}
                style={{ backgroundColor: TagClass ? TagClass : "" }}
              >
                {ChatTag}
              </div>
            ) : null}
            <div className="time-sec">{Time}</div>
            {/* <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", columnGap: "5px" }} >
              {
                isMuted ?
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.33317 15.8327V14.166H4.99984V8.33268C4.99984 7.87435 5.05887 7.42296 5.17692 6.97852C5.29498 6.53407 5.47206 6.11046 5.70817 5.70768L8.33317 8.33268H5.99984L2.1665 4.49935L3.33317 3.33268L16.6665 16.666L15.4998 17.8327L13.4582 15.8327H3.33317ZM14.9998 12.6243L6.83317 4.45768C7.11095 4.23546 7.40956 4.04102 7.729 3.87435C8.04845 3.70768 8.38873 3.58268 8.74984 3.49935V2.91602C8.74984 2.56879 8.87137 2.27365 9.11442 2.0306C9.35748 1.78754 9.65262 1.66602 9.99984 1.66602C10.3471 1.66602 10.6422 1.78754 10.8853 2.0306C11.1283 2.27365 11.2498 2.56879 11.2498 2.91602V3.49935C12.3609 3.77713 13.2637 4.36393 13.9582 5.25977C14.6526 6.1556 14.9998 7.1799 14.9998 8.33268V12.6243ZM9.99984 18.3327C9.5415 18.3327 9.14914 18.1695 8.82275 17.8431C8.49637 17.5167 8.33317 17.1243 8.33317 16.666H11.6665C11.6665 17.1243 11.5033 17.5167 11.1769 17.8431C10.8505 18.1695 10.4582 18.3327 9.99984 18.3327Z" fill="#5F6368" />
                  </svg>
                  : null
              }
              {MessageNumber > 0 ? (
                <div className="chat-msg-badge">{MessageNumber}</div>
              ) : null}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Userlist;
