import Wtable from "../table/table";
import Form from "react-bootstrap/Form";
import React, { useEffect, useState, useRef } from "react";
import "./modalTable.css";
import Checkbox from "../group-table/checkbox";

const Modaltable = ({
  contacts,
  setSelectedContacts,
  fileUploaded,
  setInvalidRecordsLength,
}) => {
  console.log("contactsssss", contacts, "::", fileUploaded);

  const [selected, setSelected] = useState([]);

  const [isHeaderChecked, setIsHeaderChecked] = useState(false);
  const headerCheckboxRef = useRef();

  const handleHeaderCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsHeaderChecked(isChecked);
    if (isChecked) {
      setSelected(contacts);
    } else {
      setSelected([]);
    }

    const checkboxes = document.querySelectorAll(
      '.modal-table input[type="checkbox"]'
    );
    checkboxes.forEach((checkbox) => {
      checkbox.checked = isChecked;
    });
  };

  // useEffect(() => {
  //   setSelectedContacts(tbodyData1);
  // }, [tbodyData1]);

  // useEffect(() => {
  //   if (selected.length < contacts.length) {
  //     setIsHeaderChecked(false);
  //     headerCheckboxRef.current.checked = false; // Update the header checkbox in the DOM
  //   } else if (selected.length === contacts.length && selected.length !== 0) {
  //     setIsHeaderChecked(true);
  //     headerCheckboxRef.current.checked = true; // Update the header checkbox in the DOM
  //   }
  // }, [selected, contacts]);

  function removeNonNumericCharacters(str) {
    const regex = /[^0-9]/g;
    return str.replace(regex, "");
  }

  const theadData = [
    // <>
    //   <Form.Check
    //     type="Checkbox"
    //     onChange={handleHeaderCheckboxChange}
    //     ref={headerCheckboxRef}
    //   />
    // </>,
    "Name",
    "Number",
  ];
  const allNonEmptyRecords = contacts.filter(
    (item) => item.contactName != "" || item?.number != ""
  );
  console.log("contacts ====", contacts, "::::::", allNonEmptyRecords);
  const tbodyData1 = allNonEmptyRecords.map((contact, index) => {
    console.log("tbidjj", contact);
    if (contact?.invalid) {
      // setSelectedContacts({
      //   items: [contact.contactName, "+" + contact.number],
      // });
      return {
        // style: { background: contact?.invalid ? "#FFDCD1" : "" },
        id: index,
        items:
          contact.number != ""
            ? [contact.contactName, "+" + contact.number]
            : [contact.contactName, contact.number],
      };
    } else {
      return {
        style: {},
        id: index,
        items: [],
      };
    }
  });

  console.log("tbodyData1", tbodyData1);

  useEffect(() => {
    let inValidLength = 0;
    const allNonEmptyRecords = contacts.filter(
      (item) => item.contactName != "" || item?.number != ""
    );
    allNonEmptyRecords.map((contact, index) => {
      if (contact?.invalid) {
        inValidLength += 1;
      }
    });
    setInvalidRecordsLength(inValidLength);
    console.log("inValidLength", inValidLength);
  }, [tbodyData1]);

  console.log("hello");

  return (
    <>
      <div className="modal-table">
        <Wtable
          theadData={theadData}
          isCsvTable
          tbodyData={tbodyData1}
          fileUploaded={fileUploaded}
        />
        {fileUploaded && contacts.length === 0 && (
          <p
            style={{
              marginBottom: "0",
              textAlign: "center",
              fontWeight: "450",
            }}
          >
            No contacts could be found in the file uploaded
          </p>
        )}
      </div>
    </>
  );
};

export default Modaltable;
