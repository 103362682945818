import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReactPlayer from "react-player";
import { Marker, GoogleMap, useLoadScript } from "@react-google-maps/api";
import { Box } from "@mui/material";
import { getLocTemplateCoordinates } from "../../services/WhatsappAPI/index";
import useDeviceWidthDetector from "../customHooks/DeviceWidthDetector.jsx";
import pdfIcon from "../../images/icons/pdf-icon.svg";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "../../pdf-worker.js";
import Reciver from "../../images/icons/reciver.svg";
import Linkforward from "../../images/icons/link-forward.svg";
import Cloasebtnic from "../../images/close-icon.svg";
import { Link } from "react-router-dom";
import Eventimg from "../../images/event-img.jpg";

const GOOGLE_API_KEY = "AIzaSyASVOh0zf-dgKxPOr4e796luwQzCT150NY";
const libraries = ["places"];

const TemplateBodymob = ({
  CategoryTitle,
  LanguageTitle,
  template,
  templates,
  onClickDelete,
  showDeleteModal,
  setShowDeletModal,
  isDeleting,
  editTemplate,
  selIndex,
  index,
  setSelIndex,
  onChildC,
  activeTempIcon,
  templateDataNew,
}) => {
  console.log("template in mobile body :", template);
  const [dtshow, setDtShow] = useState(false);
  const [templateshow, setTemplateShow] = useState(false);
  const [templateData, setTemplateData] = useState(null);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [pdfViewWidth, setPdfViewWidth] = useState(300);
  const windowWidth = useDeviceWidthDetector();

  const getPreviewHeight = () => {
    const previewSection = document.getElementsByClassName("heloooo");
    console.log("off set width : ", previewSection[0]?.clientWidth);
    setPdfViewWidth(previewSection[0]?.clientWidth);
  };
  // getting message preview width for pdf view
  useEffect(() => {
    getPreviewHeight();
  }, [windowWidth]);

  const { isLoaded: isGoogleLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: libraries,
  });

  const containerStyleForPreview = {
    width: "100%",
    height: "150px",
  };

  const options = {
    mapTypeControl: false, // Disable Map and Satellite options
    streetViewControl: false, // Disable Pegman (Street View)
    zoomControl: false, // Disable Zoom in/out controls
    fullscreenControl: false, // Disable Fullscreen control
  };

  const getTemplCoods = async (templateId) => {
    const companyId = localStorage.getItem("companyId");
    const coordinates = await getLocTemplateCoordinates(companyId, templateId);
    console.log(
      "coordinates ",
      coordinates,
      ":::",
      coordinates?.data?.components[0]?.parameters[0]?.location
    );
    setCoordinates({
      lat: coordinates?.data?.components[0]?.parameters[0]?.location?.latitude,
      lng: coordinates?.data?.components[0]?.parameters[0]?.location?.longitude,
    });
  };

  const getTemplateData = () => {
    let template = templates[index];
    console.log("template", template);
    const headerComponent = template.components.find(
      (c) => c.type === "HEADER"
    );
    const bodyComponent = template.components.find((c) => c.type === "BODY");
    const footerComponent = template.components.find(
      (c) => c.type === "FOOTER"
    );
    const buttonsComponent = template.components.find(
      (c) => c.type === "BUTTONS"
    );
    console.log("buttonsComponent", buttonsComponent);
    setTemplateData({
      name: template.name,
      language: template.language,
      header: headerComponent,
      body: bodyComponent
        ? bodyComponent.text?.split("\n").map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))
        : "",
      footer: footerComponent,
      buttons: buttonsComponent
        ? buttonsComponent.buttons.map((btn) => ({
            type: btn.type,
            text: btn.text,
          }))
        : [],
    });
    setCoordinates({ lat: null, lng: null });
    if (headerComponent?.format == "LOCATION") {
      getTemplCoods(template?.id);
    }

    // {templateData?.body
    //     ?.split("\n")
    //     .map((line, index) => (
    //       <span key={index}>
    //         {line}
    //         <br />
    //       </span>
    //     ))}

    setTemplateShow(true);

    onChildC();
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <div className="template-accordion-body d-flex align-items-center justify-content-between mb-3">
        <div className="temp-sec-title">Category</div>
        <div className="title-descp" style={{ fontSize: "14px" }}>
          {CategoryTitle}
        </div>
      </div>

      <div className="template-accordion-body d-flex align-items-center justify-content-between mb-3">
        <div className="temp-sec-title">Language</div>
        <div className="title-descp" style={{ fontSize: "14px" }}>
          {LanguageTitle?.toUpperCase()}
        </div>
      </div>

      <div className="template-accordion-body d-flex align-items-center justify-content-end">
        <div className="action">
          <ul className="nav">
            <li className="nav-item">
              <button
                className="action-b"
                onClick={() => {
                  getTemplateData();
                  setSelIndex(index);
                }}
              >
                {templateData?.name == templateDataNew?.name ? (
                  <img
                    src={activeTempIcon}
                    style={{ width: "16px", height: "10px" }}
                  />
                ) : (
                  <svg
                    width="16"
                    height="10"
                    viewBox="0 0 16 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.99984 1.33333C9.22566 1.32926 10.4278 1.67119 11.4679 2.31982C12.5081 2.96845 13.3441 3.89744 13.8798 5C12.7798 7.24667 10.5332 8.66667 7.99984 8.66667C5.4665 8.66667 3.21984 7.24667 2.11984 5C2.65558 3.89744 3.49159 2.96845 4.53176 2.31982C5.57192 1.67119 6.77401 1.32926 7.99984 1.33333ZM7.99984 0C4.6665 0 1.81984 2.07333 0.666504 5C1.81984 7.92667 4.6665 10 7.99984 10C11.3332 10 14.1798 7.92667 15.3332 5C14.1798 2.07333 11.3332 0 7.99984 0ZM7.99984 3.33333C8.44186 3.33333 8.86579 3.50893 9.17835 3.82149C9.49091 4.13405 9.6665 4.55797 9.6665 5C9.6665 5.44203 9.49091 5.86595 9.17835 6.17851C8.86579 6.49107 8.44186 6.66667 7.99984 6.66667C7.55781 6.66667 7.13389 6.49107 6.82133 6.17851C6.50877 5.86595 6.33317 5.44203 6.33317 5C6.33317 4.55797 6.50877 4.13405 6.82133 3.82149C7.13389 3.50893 7.55781 3.33333 7.99984 3.33333ZM7.99984 2C6.3465 2 4.99984 3.34667 4.99984 5C4.99984 6.65333 6.3465 8 7.99984 8C9.65317 8 10.9998 6.65333 10.9998 5C10.9998 3.34667 9.65317 2 7.99984 2Z"
                      fill="black"
                      fillOpacity="0.6"
                    />
                  </svg>
                )}
              </button>
            </li>
            <li className="nav-item" onClick={editTemplate}>
              <Link to="/createTemplate" className="action-b">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.75 12.25H2.8L9.26875 5.78125L8.21875 4.73125L1.75 11.2V12.25ZM12.475 4.69375L9.2875 1.54375L10.3375 0.49375C10.625 0.20625 10.9783 0.0625 11.3973 0.0625C11.8158 0.0625 12.1687 0.20625 12.4562 0.49375L13.5062 1.54375C13.7937 1.83125 13.9437 2.17825 13.9562 2.58475C13.9688 2.99075 13.8313 3.3375 13.5438 3.625L12.475 4.69375ZM11.3875 5.8L3.4375 13.75H0.25V10.5625L8.2 2.6125L11.3875 5.8ZM8.74375 5.25625L8.21875 4.73125L9.26875 5.78125L8.74375 5.25625Z"
                    fill="black"
                    fillOpacity={"0.6"}
                  />
                </svg>
              </Link>
            </li>
            <li className="nav-item">
              <button
                className="action-b"
                onClick={() => {
                  setShowDeletModal(true);
                  setSelIndex(index);
                }}
              >
                <svg
                  width="12"
                  height="14"
                  viewBox="0 0 12 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 13.75C1.8375 13.75 1.4845 13.6033 1.191 13.3097C0.897 13.0157 0.75 12.6625 0.75 12.25V2.5H0V1H3.75V0.25H8.25V1H12V2.5H11.25V12.25C11.25 12.6625 11.1033 13.0157 10.8097 13.3097C10.5157 13.6033 10.1625 13.75 9.75 13.75H2.25ZM9.75 2.5H2.25V12.25H9.75V2.5ZM3.75 10.75H5.25V4H3.75V10.75ZM6.75 10.75H8.25V4H6.75V10.75Z"
                    fill="black"
                    fillOpacity={"0.6"}
                  />
                </svg>
              </button>
            </li>
          </ul>
        </div>
      </div>

      <Modal
        show={dtshow}
        onHide={() => setDtShow(false)}
        className="whatsapp-modal confirm-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-content">
            You are about to delete{" "}
            <span className="u-name">
              “<b>Template Name</b>“
            </span>{" "}
            from your templates. This process cannot be reversed.
          </div>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button variant="danger-light" onClick={() => setDtShow(false)}>
            Yes, Delete it
          </Button>
          <Button variant="danger" onClick={() => setDtShow(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={templateshow}
        onHide={() => setTemplateShow(false)}
        className="whatsapp-modal modal-contact preview-template"
        centered
      >
        <Modal.Body className="py-0">
          <div
            className="preview-sec preview-sec-new"
            style={{
              backgroundColor: "transparent",
              backgroundImage: "none",
              padding: "0px",
            }}
          >
            <div className="prev-sec-header pb-2">
              <button
                className="close-preview"
                onClick={() => setTemplateShow(false)}
              >
                <img src={Cloasebtnic} alt="close-btn" />
              </button>
            </div>
            <div className="preview-body">
              <div className="preview-screen">
                {templateData?.header &&
                  templateData?.header?.format == "IMAGE" && (
                    <div className="event-img">
                      <img
                        src={templateData?.header?.example?.header_handle[0]}
                        alt="event-img"
                        className="w-100 mb-2"
                        style={{ height: "200px" }}
                      />
                    </div>
                  )}
                {templateData?.header &&
                  templateData?.header?.format == "VIDEO" && (
                    <div className="event-img">
                      <ReactPlayer
                        controls
                        url={templateData?.header?.example?.header_handle[0]}
                        width="100%"
                        height={"150px"}
                      />
                    </div>
                  )}
                {templateData?.header &&
                  templateData?.header?.format == "DOCUMENT" && (
                    <div className="event-img">
                      <div
                        style={{
                          display: "flex",
                          background: "#BCF1D5",
                          padding: "10px",
                          borderRadius: "2px",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            maxHeight: "140px",
                            overflow: "hidden",
                            width: "100%",
                          }}
                          className="heloooo"
                        >
                          {/* <Document file={{ url: templateData?.header?.example?.header_handle[0] }} onLoadSuccess={onDocumentLoadSuccess} >
                                                    <Page pageNumber={1}
                                                        renderAnnotationLayer={false}
                                                        renderTextLayer={false}
                                                        width={pdfViewWidth}
                                                    />
                                                </Document> */}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            width: "100%",
                            gap: "10px",
                          }}
                          onClick={() =>
                            window
                              .open(
                                templateData?.header?.example?.header_handle[0],
                                "_blank"
                              )
                              ?.focus()
                          }
                        >
                          <img
                            src={pdfIcon}
                            style={{ width: "32px", height: "38px" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                              flexDirection: "column",
                            }}
                          >
                            <p
                              style={{
                                fontWeight: 500,
                                fontSize: windowWidth > 1024 ? "14px" : "12px",
                                fontFamily: "Segoe UI",
                                color: "#000000",
                                cursor: "pointer",
                              }}
                            >
                              Preview File
                            </p>
                            {/* <p style={{ fontWeight: 500, fontSize: windowWidth > 1024 ? "14px" : "12px", fontFamily: "Segoe UI", color: "rgba(0, 0, 0, 0.6)" }} >{numPages} pages - PDF - {templateData?.header?.example?.header_handle[0].size}</p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {templateData?.header &&
                  templateData?.header?.format == "LOCATION" && (
                    <div className="event-img">
                      {coordinates?.lat == null || coordinates?.lng == null ? (
                        <p>Loading ....</p>
                      ) : (
                        <GoogleMap
                          center={coordinates}
                          zoom={15}
                          mapContainerStyle={containerStyleForPreview}
                          options={options}
                        >
                          <Marker position={coordinates} />
                        </GoogleMap>
                      )}
                    </div>
                  )}
                {templateData?.header &&
                  templateData?.header?.format == "TEXT" && (
                    <div className="heading">{templateData?.header?.text}</div>
                  )}
                <div className="content">{templateData?.body}</div>
                <div className="heading">{templateData?.footer?.text}</div>
              </div>
              {
                <>
                  {templateData?.buttons[0]?.type == "QUICK_REPLY" ? (
                    <Row
                      className="flex justify-center items-center event-time border-top"
                      style={{
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {templateData?.buttons[0]?.type == "QUICK_REPLY" &&
                        templateData?.buttons[0] && (
                          <Col
                            xs={6}
                            className=""
                            style={{ paddingRight: "2px" }}
                          >
                            <Box className="bg-[#FFFFFF] flex justify-center items-center p-[12px] rounded-md w-100">
                              <h4
                                style={{
                                  fontFamily: "SEGOE UI",
                                  textAlign: "center",
                                }}
                                className="text-[14px]  font-medium leading-[18px] text-[#0C7FD1]"
                              >
                                {templateData?.buttons[0]?.text}
                              </h4>
                            </Box>
                          </Col>
                        )}

                      {templateData?.buttons[0]?.type == "QUICK_REPLY" &&
                        templateData?.buttons[1] && (
                          <Col
                            xs={6}
                            style={{ paddingLeft: "2px" }}
                            className=" w-50"
                          >
                            <Box className="bg-[#FFFFFF] flex justify-center items-center p-[12px] rounded-md w-100">
                              <h4
                                style={{
                                  fontFamily: "SEGOE UI",
                                  textAlign: "center",
                                }}
                                className="text-[14px]  font-medium leading-[18px] text-[#0C7FD1]"
                              >
                                {templateData?.buttons[0]?.text}
                              </h4>
                            </Box>
                          </Col>
                        )}

                      {templateData?.buttons[0]?.type == "QUICK_REPLY" &&
                        templateData?.buttons[2] && (
                          <div className="event-time">
                            <Col xs={12} className="pe-0">
                              <Box className="bg-[#FFFFFF] flex justify-center items-center p-[12px] rounded-md w-100">
                                <h4
                                  style={{
                                    fontFamily: "SEGOE UI",
                                    textAlign: "center",
                                  }}
                                  className="text-[14px]  font-medium leading-[18px] text-[#0C7FD1]"
                                >
                                  {templateData?.buttons[2]?.text}
                                </h4>
                              </Box>
                            </Col>
                          </div>
                        )}
                    </Row>
                  ) : null}

                  {templateData?.buttons[0]?.type == "PHONE_NUMBER" ||
                  templateData?.buttons[1]?.type == "PHONE_NUMBER" ||
                  templateData?.buttons[0]?.type == "URL" ||
                  templateData?.buttons[1]?.type == "URL" ? (
                    <Row
                      className="event-time border-top"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        rowGap: "10px",
                      }}
                    >
                      {templateData?.buttons[0]?.type == "PHONE_NUMBER" ||
                      templateData?.buttons[1]?.type == "PHONE_NUMBER" ? (
                        <div
                          className="link-footer px-0"
                          style={{ padding: 0 }}
                        >
                          <div>
                            {
                              <a href="#" style={{ marginTop: "5px" }}>
                                <img src={Reciver} alt="icon" />
                                {templateData?.buttons[1]?.type ==
                                "PHONE_NUMBER"
                                  ? templateData?.buttons[1]?.text
                                  : templateData?.buttons[0]?.text}
                              </a>
                            }
                          </div>
                        </div>
                      ) : null}
                      {templateData?.buttons[1]?.type == "URL" ||
                      templateData?.buttons[0]?.type == "URL" ? (
                        <div
                          className="link-footer px-0"
                          style={{ padding: 0 }}
                        >
                          <div>
                            {
                              <a href="#" style={{ marginTop: "5px" }}>
                                <img src={Linkforward} alt="icon" />
                                {templateData?.buttons[1]?.type == "URL"
                                  ? templateData?.buttons[1]?.text
                                  : templateData?.buttons[0]?.text}
                              </a>
                            }
                          </div>
                        </div>
                      ) : null}
                    </Row>
                  ) : null}
                </>
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TemplateBodymob;
