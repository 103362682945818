import { toast } from "react-toastify";
import config from "../config";
import axios from "axios";

const baseUrl = `${config.backend}user/`;
let retry = false;
let requestQueue = [];
export const handleApiError = async (error) => {
  console.error("An error occurred:", error);
  const originalRequest = error.config;
  console.log("originalRequest", originalRequest);
  if (error.response.status === 401) {
    const requestPromise = new Promise((resolve, reject) => {
      requestQueue.push({ resolve, reject, originalRequest });
    });
    if (!retry) {
      retry = true;
      try {
        const oldRefreshToken = localStorage.getItem("refreshToken");
        const response = await axios.get(
          `${baseUrl}refresh?refreshToken=${oldRefreshToken}`
        );
        const { accessToken, refreshToken } = response.data;
        // Store the new tokens in local storage
        if (accessToken && refreshToken) {
          localStorage.setItem("token", accessToken);
          localStorage.setItem("refreshToken", refreshToken);
        }
        requestQueue.forEach(({ originalRequest }) => {
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        });

        console.log("response from interceptors", response);
        if (response?.status === 401) {
          window.location.href = "/login";
          return;
        }
        const responses = await Promise.all(
          requestQueue.map(({ resolve, reject, originalRequest }) =>
            axios(originalRequest)
              .then((response) => {
                resolve(response);
                window.location.href = window.location.href;

                return response?.data;
              })
              .catch((error) => {
                reject(error); // Reject the promise if the API call fails
                return Promise.reject(error);
              })
          )
        );
        requestQueue = []; // Clear the queue
        retry = false;

        // return responses; // Return the response for the original request
      } catch (refreshError) {
        requestQueue.forEach(({ reject }) => reject(refreshError));
        requestQueue = []; // Clear the queue
        retry = false;
        return Promise.reject(refreshError);
      }
    }
    return requestPromise;
  }

  // if (error.response) {
  //   console.log("Server responded with:", error.response.status);
  //   // toast.error(error.response.data.message);
  //   return error.response;
  // } else if (error.request) {
  //   console.log("Request was made, but no response received");
  //   // toast.error("No/Slow internet connection detected, please hold on or refresh");
  //   console.log(
  //     "No/Slow internet connection detected, please hold on or refresh"
  //   );
  // } else {
  //   console.log("Error occurred during request setup:", error.message);
  //   toast.error("There was an error in the system, please refresh");
  // }
  return Promise.reject(error);
};
